import { Divider, makeStyles, tokens, Text } from "@fluentui/react-components";
import { TimeIndicator } from "./TimeIndicator";
import { ReactComponent as Stadium } from "./stadium.svg";
import { ReactComponent as Whistle } from "./Whistle.svg";
import { MOBILE_SHIFT } from "../../pageStyle";

const useStadiumStyle = makeStyles({
  stadiumClass: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
    backgroundColor: tokens.colorNeutralBackground3,
  },
  infoLine: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  stadiumInfo: {
    justifyContent: "start",
    display: "flex",
    alignItems: "center",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      justifyContent: "center",
    },
  },
});

export function InfoLine({
  stadium = null,
  startDate,
  referee = null,
}: {
  stadium?: string | null;
  startDate: string;
  referee?: string | null;
}) {
  const { stadiumClass, infoLine, stadiumInfo } = useStadiumStyle();

  return (
    <div className={stadiumClass}>
      <Divider
        style={{
          padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalNone}`,
          flexGrow: 0,
        }}
      />
      <div className={infoLine}>
        <TimeIndicator time={startDate} />
        <div className={stadiumInfo}>
          <Stadium />
          <Text style={{ paddingLeft: tokens.spacingHorizontalXS }}>
            {stadium}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Whistle />
          <Text style={{ paddingLeft: tokens.spacingHorizontalXS }}>
            {referee}
          </Text>
        </div>
      </div>
      <Divider
        style={{
          padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalNone} ${tokens.spacingVerticalNone} ${tokens.spacingHorizontalNone}`,
          flexGrow: 0,
        }}
      />
    </div>
  );
}
