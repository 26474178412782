import {
  makeStyles,
  Subtitle2Stronger,
  Tab,
  TabList,
  tokens,
  Text,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuItemRadio,
  MenuList,
} from "@fluentui/react-components";
import { IosArrow24Filled } from "@fluentui/react-icons";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { usePronoMatch } from "../../../store";
import { isMatchSelected, MatchSelected } from "../../../type";
import { MOBILE_SHIFT } from "../../pageStyle";
import { Olympics } from "../../../utils";

const usePronosticHeader = makeStyles({
  tabsRow: {
    zIndex: 10,
    position: "relative",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "grid",
      gridTemplateColumns: Olympics
        ? "repeat(3, minmax(0, 1fr)) 0.5fr"
        : "repeat(4, minmax(0, 1fr)) 0.5fr 0.5fr",
      alignItems: "center",
      justifyItems: "center",
      width: "calc(100% - 10px)",
    },
  },
  points: {
    display: "none",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "block",
      gridColumnStart: Olympics ? 4 : 5,
    },
  },
  leftTab: {
    "&::after": {
      left: tokens.spacingHorizontalNone,
      right: tokens.spacingHorizontalM,
    },
    "&::before": {
      left: tokens.spacingHorizontalNone,
      right: tokens.spacingHorizontalM,
    },
  },
});

const all = process.env.REACT_APP_OLYMPICS
  ? "olympics.tabs.all"
  : "matchs.tabs.all";

export function PronosticsHeader() {
  const { t } = useTranslation();
  const { tab, setTab } = usePronoMatch();
  const { tabsRow, points, leftTab } = usePronosticHeader();
  const desktop = useMediaQuery(`(min-width: ${MOBILE_SHIFT})`);
  const ref = useRef(null);

  return (
    <div>
      {desktop && <Subtitle2Stronger>{t("matchs.prono")}</Subtitle2Stronger>}
      <div className={tabsRow} ref={ref}>
        {desktop && (
          <TabList
            selectedValue={tab}
            onTabSelect={(_, d) => {
              if (isMatchSelected(d.value)) {
                setTab(d.value);
              }
            }}
          >
            <Tab
              value={MatchSelected.All}
              style={{ paddingLeft: 0 }}
              className={leftTab}
            >
              {t(all)}
            </Tab>
            <Tab value={MatchSelected.Coming}>{t("matchs.tabs.next")}</Tab>
            <Tab value={MatchSelected.Current}>{t("matchs.tabs.current")}</Tab>
            <Tab value={MatchSelected.Finished}>
              {t("matchs.tabs.finished")}
            </Tab>
          </TabList>
        )}
        {desktop === false && (
          <Menu
            checkedValues={{ tab: [tab] }}
            onCheckedValueChange={(e, data) => {
              setTab(data.checkedItems[0] as MatchSelected);
            }}
            mountNode={ref.current}
          >
            <MenuTrigger disableButtonEnhancement>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: tokens.spacingHorizontalS,
                }}
              >
                <Text size={500} weight={"semibold"}>
                  {t(
                    tab === MatchSelected.All && process.env.REACT_APP_OLYMPICS
                      ? "olympics.tabs.all"
                      : `matchs.tabs.${tab}`
                  )}
                </Text>
                <IosArrow24Filled
                  style={{ height: "15px", rotate: "-90deg" }}
                />
              </div>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItemRadio
                  name="tab"
                  value={MatchSelected.All}
                  content={<Text>{t(all)}</Text>}
                ></MenuItemRadio>
                <MenuItemRadio name="tab" value={MatchSelected.Coming}>
                  {t("matchs.tabs.next")}
                </MenuItemRadio>
                <MenuItemRadio name="tab" value={MatchSelected.Current}>
                  {t("matchs.tabs.current")}
                </MenuItemRadio>
                <MenuItemRadio name="tab" value={MatchSelected.Finished}>
                  {t("matchs.tabs.finished")}
                </MenuItemRadio>
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
        <Text className={points} size={300} weight="semibold">
          {t("matchs.points")}
        </Text>
      </div>
    </div>
  );
}
