import { CounterBadge, makeStyles, tokens } from "@fluentui/react-components";
import { ArrowDown20Regular } from "@fluentui/react-icons";
import { ChangeStatus } from "@winnerscore/common";

const useStyle = makeStyles({
  up: {
    rotate: "180deg",
    color: "#6BB700",
  },
  constant: {
    rotate: "-90deg",
    color: "#FFA538",
  },
  down: {
    color: "#BF1F1F",
  },
});

export function Rank({ rank, change }: { rank: number; change: ChangeStatus }) {
  const first = rank > 3 ? false : true;
  const style = useStyle();
  if (rank > 0) {
    return (
      <>
        <CounterBadge
          appearance={first ? "filled" : "ghost"}
          color="important"
          count={rank}
          overflowCount={Number.POSITIVE_INFINITY}
          size="large"
        />
        <ArrowDown20Regular
          style={{
            width: "16px",
            height: "16px",
            marginLeft: tokens.spacingVerticalXXS,
          }}
          className={
            change === "up"
              ? style.up
              : change === "down"
                ? style.down
                : style.constant
          }
        />
      </>
    );
  }
  return null;
}
