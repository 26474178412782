import { Text } from "@fluentui/react-components";
import { Progress } from "./Progress";

const stringLabel: Record<string, boolean> = {
  "Passes %": true,
  "Possession de balle": true,
  "Ball possesion": true,
};

export function StatRow({
  label,
  valueA,
  valueB,
  percent,
}: {
  label: string;
  valueA: number | string;
  valueB: number | string;
  percent: number;
}) {
  const left = stringLabel[label] ? `${valueA}%` : `${valueA}`;
  const right = stringLabel[label] ? `${valueB}%` : `${valueB}`;
  return (
    <>
      <Text align="center">{label}</Text>
      <Progress percent={percent} valueLeft={left} valueRight={right} />
    </>
  );
}
