import { makeStyles, shorthands } from "@fluentui/react-components";
import { useMediaQuery } from "usehooks-ts";
import { useTabDetail } from "../../../store";
import { MatchPage, TabValue } from "../../../type";
import { LAYOUT_SHIFT } from "../../pageStyle";
import { StatDetail } from "./Stats/StatDetail";
import { MatchStatistics } from "./Stats/MatchStatistics";

const useDetailsStyle = makeStyles({
  wrapper: {
    display: "grid",
    flexGrow: 1,
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "34px 1fr",
    ...shorthands.gridArea("main"),
    gridTemplateAreas: `"title-left title-right"
                        "content-left content-right"`,
    width: "min(100%, 776px)",
    alignSelf: "center",
  },
});

const Content = ({ match }: { match: MatchPage }) => {
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const currentDetail = useTabDetail((s) => s.value);
  return (
    <>
      {(desktop || currentDetail === TabValue.Players) && (
        <StatDetail match={match} />
      )}
      {(desktop || currentDetail === TabValue.Stats) && (
        <MatchStatistics match={match} />
      )}
    </>
  );
};

export function DetailsContent({
  wrap = false,
  match,
}: {
  wrap?: boolean;
  match: MatchPage;
}) {
  const { wrapper } = useDetailsStyle();
  return wrap ? (
    <div className={wrapper}>
      <Content match={match} />
    </div>
  ) : (
    <Content match={match} />
  );
}
