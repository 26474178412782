import { Image } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import Question from "./question.png";

export function BackupImage({
  url,
  alt,
  size,
  fit = "default",
  shape = "square",
}: {
  url: string;
  alt: string;
  size?: number;
  fit?: "cover" | "none" | "center" | "contain" | "default";
  shape?: "square" | "circular" | "rounded";
}) {
  const [src, setSrc] = useState(url);

  useEffect(() => {
    setSrc(url);
  }, [url]);

  return (
    <Image
      src={src}
      alt={alt}
      width={size}
      height={size}
      fit={fit}
      shape={shape}
      onError={() => {
        if (src === url) {
          setSrc(Question);
        }
      }}
    />
  );
}
