import {
  makeStyles,
  shorthands,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { useTabDetail } from "../../../store";
import { TabValue } from "../../../type";
import { LAYOUT_SHIFT, MOBILE_SHIFT } from "../../pageStyle";

const useFooterStyle = makeStyles({
  tabs: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100vw",
    justifyContent: "center",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "none",
    },
    ...shorthands.gridArea("tabs"),
  },
});

export function DetailTab() {
  const { tabs } = useFooterStyle();
  const { value, set } = useTabDetail();
  const { t } = useTranslation();
  const mobile = useMediaQuery(`(max-width: ${MOBILE_SHIFT})`);
  return (
    <>
      <TabList
        onTabSelect={(_, data) => {
          set(data.value as TabValue);
        }}
        className={tabs}
        selectedValue={value}
      >
        <Tab value={TabValue.Chat}>{t("chat.chat")}</Tab>
        <Tab value={TabValue.Players}>
          {mobile ? t("matchs.statsDetailShort") : t("matchs.statDetail")}
        </Tab>
        <Tab value={TabValue.Stats}>
          {mobile ? t("matchs.statsShort") : t("matchs.stats")}
        </Tab>
      </TabList>
    </>
  );
}
