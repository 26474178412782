import {
  Body1Strong,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Example } from "./Example";

const usePadding = makeStyles({
  padding: {
    ...shorthands.padding(
      tokens.spacingVerticalL,
      tokens.spacingHorizontalNone
    ),
  },
});

export function RulesContent() {
  const { t } = useTranslation();
  const { padding } = usePadding();
  return (
    <>
      <Body1Strong block className={padding}>
        {t("rules.goal")}
      </Body1Strong>
      <Text as="p" className={padding}>
        {t("rules.rules")}
      </Text>
      <Body1Strong block className={padding}>
        {t("rules.example.title")}
      </Body1Strong>
      <ul>
        <Example text={t("rules.example.ex1")} />
        <Example text={t("rules.example.ex2")} />
        <Example text={t("rules.example.ex3")} />
      </ul>
      <Body1Strong block className={padding}>
        {t("rules.example.pointsCount")}
      </Body1Strong>
      <Text as="p" className={padding}>
        {t("rules.example.pointsDesc")}
      </Text>
      <Example text={t("rules.example.point")} />
    </>
  );
}
