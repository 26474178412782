import {
  makeStyles,
  shorthands,
  tokens,
  Text,
} from "@fluentui/react-components";
import { BackupImage } from "../../common/BackupImage";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  background: {
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
    backgroundColor: tokens.colorNeutralBackground5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export function FlagWithBackground({
  url,
  name,
  size = 40,
}: {
  url: string;
  name?: string;
  size?: number;
}) {
  const { background, container } = useStyle();
  const imageSize = size ? size : 48;
  const backgroundSize = Math.round(1.6 * imageSize);
  return (
    <div className={container}>
      <div
        className={background}
        style={{ width: backgroundSize, height: backgroundSize }}
      >
        <span
          style={{
            width: imageSize,
            height: imageSize,
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <BackupImage
            url={url}
            alt={`${name} flag`}
            shape="circular"
            size={imageSize}
            fit="contain"
          />
        </span>
      </div>
      {name && (
        <Text align="center" size={500}>
          {name}
        </Text>
      )}
    </div>
  );
}
