import {
  Input,
  makeStyles,
  Text,
  tokens,
  shorthands,
} from "@fluentui/react-components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isPronostic } from "@winnerscore/common";
import { useEffect, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { getProno, setProno } from "../../../api";
import { Match } from "../../../type";
import { PRONOSTICS } from "../../../utils";
import { MOBILE_SHIFT } from "../../pageStyle";
import { useTimeFormatter } from "./useTimeFormatter";

const useScore = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      alignItems: "center",
    },
  },
  input: {
    "@media (min-width: 350px)": {
      width: "40px",
      height: "40px",
    },
    width: "30px",
    height: "30px",
    ...shorthands.padding(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalNone
    ),
  },
  text: {
    ...shorthands.padding(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalL
    ),
  },
  summary: {
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "none",
    },
    gridColumnStart: 1,
    gridColumnEnd: 4,
    alignSelf: "end",
  },
});

export function PronoScore({ match }: { match: Match }) {
  const { container, input, text, summary } = useScore();
  const [scoreLeft, setScoreLeft] = useState("");
  const [scoreRight, setScoreRight] = useState("");
  const [debouncedScore, setScore] = useDebounceValue(
    scoreLeft + scoreRight,
    1000
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    setScore(scoreLeft + scoreRight);
  }, [setScore, scoreLeft, scoreRight]);

  const { data } = useQuery({
    queryKey: [PRONOSTICS, match.id],
    queryFn: async () => {
      const data = await getProno(match.id);
      return data;
    },
  });

  const scoreMutation = useMutation({
    mutationFn: ({
      matchId,
      prono,
    }: {
      matchId: number;
      prono: [number, number];
    }) =>
      setProno({
        matchId,
        prono,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRONOSTICS, match.id] });
    },
    retry: 5,
  });

  useEffect(() => {
    if (debouncedScore) {
      scoreMutation.mutate({
        matchId: match.id,
        prono: [
          scoreLeft ? Number(scoreLeft) : 0,
          scoreRight ? Number(scoreRight) : 0,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedScore]);

  useEffect(() => {
    if (data && isPronostic(data)) {
      setScoreLeft(`${data.team_a}`);
      setScoreRight(`${data.team_b}`);
    }
  }, [data]);

  const { startDate } = useTimeFormatter(match.start_date);
  const disabled = match.team_a.known === false || match.team_b.known === false;

  return (
    <div className={container}>
      <Text className={summary} align="center">
        {startDate}
      </Text>
      <Input
        type="number"
        className={input}
        min={0}
        style={{ fontSize: tokens.fontSizeBase600, justifySelf: "end" }}
        value={scoreLeft?.toString() ?? ""}
        disabled={disabled}
        onChange={(_, d) => {
          if (d.value) {
            const stringValue = d.value.slice(0, 2);
            const value = Math.max(0, Number(stringValue));
            setScoreLeft(value.toString());
          } else {
            setScoreLeft(d.value);
          }
        }}
      />
      <Text className={text} align="center">
        -
      </Text>
      <Input
        type="number"
        min={0}
        className={input}
        style={{ fontSize: tokens.fontSizeBase600 }}
        value={scoreRight?.toString() ?? ""}
        disabled={disabled}
        onChange={(_, data) => {
          if (data.value) {
            const stringValue = data.value.slice(0, 2);
            const value = Math.max(0, Number(stringValue));
            setScoreRight(value.toString());
          } else {
            setScoreRight(data.value);
          }
        }}
      />
    </div>
  );
}
