import { WebSocketServerType } from "@winnerscore/common";
import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "./store";
import { Match, MatchPage, MatchStatus } from "./type";
import { authentication } from "@microsoft/teams-js";

export const useResize = (
  node: Element | null,
  callback: (entries: ResizeObserverEntry[]) => void
) => {
  const [observer, setObserver] = useState<ResizeObserver>();
  useEffect(() => {
    if (!node) {
      return;
    }
    if (!observer) {
      const c = new ResizeObserver(callback);
      setObserver(c);
      return;
    }
    observer.observe(node);
    return () => {
      observer.disconnect();
    };
  }, [node, observer, callback]);
  return observer;
};

export const MATCH_KEY = "matchs";
export const EVENT_KEY = "events";
export const SCORES_KEY = "scores";
export const CURRENT_USER = "current";
export const USERS_KEY = "users";
export const PRONOSTICS = "pronostics";
export const TEAM_KEY = "team";
export const GIFTS_KEY = "gifts";
export const TIPSTERS_KEY = "tipsters";
export const RULES_KEY = "rules";

export const useChatSocket = ({
  matchId,
  onMessage,
}: {
  matchId?: number;
  onMessage: (message: WebSocketServerType) => void;
}) => {
  const [socket, setSocket] = useState<WebSocket | undefined>();
  const [messageQueue, setMessageQueue] = useState<
    { userId: string; message: string; userName: string }[]
  >([]);

  const createSocket = useCallback(
    (matchId?: number) => {
      if (matchId) {
        const connect = async () => {
          // Send auth message
          const token = await authentication.getAuthToken();
          // const token = await provider.getAccessToken();
          newSocket.send(JSON.stringify({ token }));
          newSocket.onclose = () => {
            setSocket(undefined);
          };
          newSocket.onmessage = (message) => {
            if (typeof message.data === "string") {
              const serverMessage: WebSocketServerType = JSON.parse(
                message.data
              );
              onMessage(serverMessage);
            }
          };

          messageQueue.forEach((m) => {
            newSocket.send(JSON.stringify(m));
          });
          setMessageQueue([]);
        };
        const newSocket = new WebSocket(
          `${process.env.REACT_APP_BACKEND_URL!.replace(
            "https",
            "wss"
          )}/chat/${matchId}`
        );
        newSocket.onopen = connect;
        newSocket.onclose = () => {
          setTimeout(() => {
            setSocket(undefined);
          }, 500);
        };
        return newSocket;
      }
    },
    [onMessage, messageQueue]
  );

  const sendMessage = useCallback(
    async (message: { userId: string; message: string; userName: string }) => {
      if (socket?.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));
      } else {
        setMessageQueue((queue) => [...queue, message]);
        setSocket(undefined);
      }
    },
    [socket]
  );

  useEffect(() => {
    if (matchId && !socket) {
      const socket = createSocket(matchId);
      setSocket(socket);
    }
  }, [createSocket, matchId, socket]);

  return { send: sendMessage };
};

type Options = {
  method: "GET" | "POST";
  body?: string;
  searchParams?: URLSearchParams;
};

export const authFetch = async <T extends unknown>(
  url: string,
  opts: Options = { method: "GET" }
): Promise<T | undefined> => {
  const token = await authentication.getAuthToken();
  // const token = await provider.getAccessToken();
  if (!token) {
    return;
  }
  const urlWithQuery = new URL(url);
  const languageCode = useLanguage.getState().lang;
  urlWithQuery.searchParams.append("lang", languageCode);
  if (opts.searchParams) {
    for (const [key, value] of opts.searchParams.entries()) {
      urlWithQuery.searchParams.append(key, value);
    }
  }
  const response = await fetch(urlWithQuery, {
    headers: {
      Authorization: `Bearer: ${token}`,
      "content-type": "application/json",
    },
    method: opts.method,
    body: opts.body,
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
};

const DELTA_ACCEPTABLE = 15 * 1000 * 60;

export const getMatchStatus = (match?: Match | MatchPage): MatchStatus => {
  if (!match) {
    return MatchStatus.Planned;
  }
  if (match.active) {
    return MatchStatus.InProgress;
  }
  const now = Date.now();
  const start = new Date(match.start_date);
  if (now < start.getTime() + DELTA_ACCEPTABLE) {
    return MatchStatus.Planned;
  }
  return MatchStatus.Ended;
};

export const Olympics = process.env.REACT_APP_OLYMPICS ? true : false;
