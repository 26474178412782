// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
  Button,
  Text,
  Spinner,
} from "@fluentui/react-components";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import { Header } from "./Header";
import { GiftPage } from "./gift/Gift";
import { Rules } from "./rules/Rules";
import { MatchDetails } from "./pronostics/MatchDetails/MatchDetails";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { Providers, ProviderState } from "@microsoft/mgt-react";
import {
  useCanUseApplication,
  useCurrentUser,
  useCompanyExist,
  useLanguage,
} from "../store";
import { provider, authConfig, credential, scopes } from "./auth";
import { PronosticsPage } from "./pronostics/PronosticsPage";
import { Faq } from "./rules/Faq";
import { RankingPage } from "./ranking/RankingPage";
import { checkNumberUsers, createUser, companyExist } from "../api";
import { createMicrosoftGraphClientWithCredential } from "@microsoft/teamsfx";
import { RegisterGifts } from "./register/Gifts";
import { useMediaQuery } from "usehooks-ts";
import { LAYOUT_SHIFT } from "./pageStyle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Error } from "./Error";

const StepOne = lazy(() =>
  import("./register/Rules").then((module) => ({
    default: module.RegisterRules,
  }))
);
const Register = lazy(() => import("./register/Register"));
const RegisterAdmin = lazy(() =>
  import("./register/Admins").then((module) => ({ default: module.Admin }))
);

/**
 * The main app which handles ./pronostics/MatchDetailst and routing
 * of the app.
 */

const queryClient = new QueryClient();

export default function App() {
  const { theme, themeString, teamsUserCredential, context } =
    useTeamsUserCredential(authConfig);
  provider.onStateChanged(() => {
    if (provider.state === ProviderState.SignedOut) {
      console.log("Log out");
      setReload((v) => v + 1);
    }
  });
  const langStore = useLanguage();
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [consentNeeded, setConsentNeeded] = useState(false);
  const { set, id, username, company } = useCurrentUser();
  const { set: setCanUse, can } = useCanUseApplication();
  const { exist, set: setExist } = useCompanyExist();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { t } = useTranslation();

  useEffect(() => {
    const isAllowed = async () => {
      const allowed = await checkNumberUsers();
      if (allowed !== undefined) {
        setCanUse(allowed);
      }
    };

    if (id) {
      isAllowed();
    }
  }, [setCanUse, id]);

  useEffect(() => {
    const init = async () => {
      try {
        await credential.getToken(scopes);
        const graphClient = createMicrosoftGraphClientWithCredential(
          credential,
          ["User.Read"]
        ); // Initializes MS Graph SDK using our MsGraphAuthProvider
        const profile: { id: string; displayName: string } = await graphClient
          .api("/me")
          .select(["id", "displayName"])
          .get();
        const org: {
          value: {
            id: string;
            displayName: string;
            city: string | null;
            postalCode: string | null;
            businessPhones: string[];
          }[];
        } = await graphClient
          .api("/organization")
          .select(["id", "displayName", "city", "postalCode", "businessPhones"])
          .get();
        set({
          id: profile.id,
          username: profile.displayName,
          company: {
            id: org.value[0].id,
            displayName: org.value[0].displayName ?? "",
            city: org.value[0].city ?? "",
            postalCode: org.value[0].postalCode ?? "",
            phones: org.value[0].businessPhones ?? [],
          },
        });
        Providers.globalProvider.setState(ProviderState.SignedIn);
      } catch (_) {
        setConsentNeeded(true);
      }
    };

    init();
  }, [set, reload]);

  const consent = async () => {
    setLoading(true);
    await credential.login(scopes);
    Providers.globalProvider.setState(ProviderState.SignedIn);
    setLoading(false);
    setConsentNeeded(false);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const locale: string = context?.app?.locale;
    if (locale) {
      langStore.set(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const createUserIfNotExist = useCallback(async () => {
    const created = await companyExist(company.id);
    if (created) {
      setExist(created);
      await createUser({
        id,
        displayName: username,
        company: company,
      });
    } else {
      setExist(false);
    }
  }, [id, username, company, setExist]);

  useEffect(() => {
    if (id && company.id) {
      createUserIfNotExist();
    }
  }, [id, createUserIfNotExist, exist, company, consentNeeded]);

  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
              ? teamsHighContrastTheme
              : teamsLightTheme
        }
        style={{
          background: tokens.colorNeutralBackground3,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              {exist && (
                <Route element={<Header />} path="/">
                  {!can && (
                    <Route
                      element={
                        <Error>
                          <Text
                            align="center"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            size={600}
                            weight="semibold"
                          >
                            {t("maxUsers")}
                          </Text>
                        </Error>
                      }
                      index
                    />
                  )}
                  {consentNeeded && can && (
                    <Route
                      element={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            appearance="primary"
                            onClick={consent}
                            disabled={loading}
                            style={{ width: "min-content" }}
                          >
                            Login
                          </Button>
                        </div>
                      }
                      index
                    />
                  )}
                  {Providers.globalProvider.state === ProviderState.SignedIn &&
                    can && (
                      <>
                        <Route
                          path="/pronostics"
                          element={<PronosticsPage />}
                        />
                        <Route
                          path="/pronostics/:matchId"
                          element={<MatchDetails />}
                        />
                        <Route
                          path="/rules"
                          element={
                            <Rules>
                              {!desktop && (
                                <div
                                  style={{
                                    marginBottom: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: tokens.spacingVerticalL,
                                  }}
                                >
                                  <Link to="/faq-page">
                                    <Button
                                      appearance="primary"
                                      style={{ width: "100%" }}
                                    >
                                      {t("faq.access")}
                                    </Button>
                                  </Link>
                                </div>
                              )}
                            </Rules>
                          }
                        />
                        <Route path="/gift" element={<GiftPage />} />
                        <Route path="/faq-page" element={<Faq />} />
                        <Route path="/ranking" element={<RankingPage />} />
                        <Route index element={<Navigate to="/pronostics" />} />
                      </>
                    )}
                </Route>
              )}
              {consentNeeded === false && (
                <>
                  <Route element={<Navigate to="/register" />} />
                  <Route
                    element={
                      <Suspense fallback={<Spinner size="large" />}>
                        <Register />
                      </Suspense>
                    }
                    path="/register"
                  />
                  <Route
                    path="/register/rules"
                    element={
                      <Suspense fallback={<Spinner size="large" />}>
                        <StepOne />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/register/gifts"
                    element={
                      <Suspense fallback={<Spinner size="large" />}>
                        <RegisterGifts companyId={company.id} />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/register/admins"
                    element={
                      <Suspense fallback={<Spinner size="large" />}>
                        <RegisterAdmin />
                      </Suspense>
                    }
                  />
                </>
              )}
              {consentNeeded && !exist && (
                <Route
                  path="*"
                  element={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                      }}
                    >
                      <Button
                        appearance="primary"
                        onClick={consent}
                        disabled={loading}
                        style={{ width: "min-content" }}
                      >
                        Login
                      </Button>
                    </div>
                  }
                />
              )}
            </Routes>
          </Router>
        </QueryClientProvider>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
