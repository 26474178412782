import { useTranslation } from "react-i18next";
import { Footer } from "./Footer";
import { Wizard } from "./Wizard";
import { useRegisterStyle } from "./registerStyle";
import {
  Button,
  Text,
  tokens,
  Card,
  CardPreview,
  CardHeader,
  CounterBadge,
  makeStyles,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  Label,
  Input,
  InputProps,
  Textarea,
  shorthands,
  DialogActions,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getRegisterGifts } from "../../api";
import { BackupImage } from "../common/BackupImage";
import { MOBILE_SHIFT } from "../pageStyle";
import { LangSelect } from "./LangSelect";
import { useDropzone } from "react-dropzone";
import { ImageRegular, MoreHorizontalRegular } from "@fluentui/react-icons";
import { useLanguage, useRegisterStore } from "../../store";
import { GiftDetail } from "../../type";
import { nanoid } from "nanoid";

const useGiftStyle = makeStyles({
  gifts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: tokens.spacingVerticalL,
    paddingTop: tokens.spacingVerticalM,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      columnGap: tokens.spacingHorizontalL,
      alignItems: "stretch",
      paddingRight: tokens.spacingHorizontalL,
    },
  },
  addMobile: {
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "none",
    },
  },
  addDesktop: {
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      display: "none",
    },
  },
  dropZone: {
    height: "130px",
    ...shorthands.border("2px", "dashed", tokens.colorNeutralForeground3Hover),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  mobileSurface: {
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      height: "100vh",
      width: "100vw",
    },
  },
});

export const RegisterGifts = ({ companyId }: { companyId: string }) => {
  const { page, content } = useRegisterStyle();
  const { t } = useTranslation();
  const gifts = useRegisterStore((state) =>
    Object.values(state.gifts).sort((a, b) => a.rank - b.rank)
  );
  const { setAllGifts } = useRegisterStore();
  const { gifts: giftsClass, addMobile } = useGiftStyle();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (companyId) {
      getRegisterGifts({ companyId }).then((gifts) => {
        const storeGifts: Record<string, GiftDetail> = {};
        gifts.forEach((gift) => {
          storeGifts[gift.id] = gift;
        });
        setAllGifts(storeGifts);
      });
    }
  }, [companyId, setAllGifts]);

  return (
    <div className={page}>
      <Wizard step={1} />
      <div className={content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL} ${tokens.spacingVerticalNone} ${tokens.spacingVerticalNone}`,
          }}
        >
          <Text block weight="bold" size={400}>
            {t("giftTitle")}
          </Text>
          <GiftAdd open={open} onOpenChange={(open) => setOpen(open)} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalNone}`,
          }}
        >
          <Button appearance="primary" className={addMobile}>
            {t("register.addGift")}
          </Button>

          <div className={giftsClass}>
            {gifts.map((gift) => (
              <GiftCard key={gift.id} gift={gift} />
            ))}
          </div>
        </div>
      </div>
      <Footer back="/register/rules" next="/register/admins" />
    </div>
  );
};

const imageUrl = (url: string) => {
  return `data:image/jpeg;base64,${url}`;
};

const GiftCard = ({ gift }: { gift: GiftDetail }) => {
  const [selected, setSelected] = useState(false);
  const { lang } = useLanguage();
  const { t } = useTranslation();
  const { deleteGift } = useRegisterStore();

  const url = useCallback(imageUrl, []);

  return (
    <>
      <GiftAdd
        gift={gift}
        open={selected}
        onOpenChange={(open) => setSelected(open)}
      />
      <Card style={{ maxWidth: "350px", width: "100%" }} selected={selected}>
        <CardPreview
          onClick={() => setSelected((state) => !state)}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalNone}`,
              width: "200px",
              height: "200px",
            }}
          >
            <BackupImage
              url={url(gift.image_file)}
              alt={gift.titles["fr"]}
              fit="contain"
            />
          </div>
        </CardPreview>
        <CardHeader
          header={<CounterBadge count={gift.rank} />}
          description={
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text
                  block
                  weight="bold"
                  style={{
                    padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalNone}`,
                  }}
                >
                  {gift.titles[lang]}
                </Text>
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MoreHorizontalRegular />
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem onClick={() => setSelected(true)}>
                        {t("edit")}
                      </MenuItem>
                      <MenuItem onClick={() => deleteGift(gift.id)}>
                        {t("delete")}
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
              <Text block>{gift.descriptions[lang]}</Text>
            </div>
          }
        />
      </Card>
    </>
  );
};

const FormInput = ({
  label,
  type = "text",
  name,
  required,
  value,
  onChange,
}: {
  label: string;
  type?: InputProps["type"];
  name: string;
  required?: boolean;
  value?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <>
      <Label
        size="large"
        style={{
          paddingBottom: tokens.spacingVerticalS,
          paddingTop: tokens.spacingVerticalM,
        }}
        required={required}
      >
        {label}
      </Label>
      <Input
        type={type}
        name={name}
        required={required}
        value={value ?? ""}
        onChange={(_, data) => {
          onChange(data.value);
        }}
      />
    </>
  );
};

const GiftAdd = ({
  gift,
  open,
  onOpenChange,
}: {
  gift?: {
    id?: number | string;
    rank: number;
    image_file: string;
    titles: Record<string, string>;
    descriptions: Record<string, string>;
  };
  open: boolean;
  onOpenChange: (val: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { addDesktop, mobileSurface } = useGiftStyle();
  const [base64, setBase64] = useState<string>();
  const [rank, setRank] = useState<number>();
  const [titles, setTitles] = useState<Record<string, string>>({});
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});
  const [image, setImage] = useState("");
  const { updateGift } = useRegisterStore();

  useEffect(() => {
    if (base64) {
      setImage(`data:image/jpeg;base64,${base64}`);
    } else if (gift?.image_file) {
      setImage(imageUrl(gift.image_file));
    }
  }, [base64, gift?.image_file]);

  useEffect(() => {
    if (open) {
      setTitles(gift?.titles ?? {});
      setRank(gift?.rank ?? undefined);
      setDescriptions(gift?.descriptions ?? {});
      setBase64(undefined);
    }
  }, [open, gift]);

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        onOpenChange(data.open);
      }}
    >
      <>
        {!gift && (
          <DialogTrigger>
            <Button
              appearance="primary"
              className={addDesktop}
              onClick={() => onOpenChange(true)}
            >
              {t("register.addGift")}
            </Button>
          </DialogTrigger>
        )}
      </>

      <DialogSurface className={mobileSurface}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!base64 && !gift?.image_file) {
              return;
            }

            updateGift({
              id: gift?.id || nanoid(),
              rank: rank ?? 1,
              image_file: base64 || gift?.image_file!,
              titles,
              descriptions,
            });
            onOpenChange(false);
          }}
        >
          <LangSelect />
          <DialogBody>
            <DialogTitle>{t("register.addGiftDialogTitle")}</DialogTitle>
            <DialogContent style={{ display: "flex", flexDirection: "column" }}>
              <EditForm
                titles={titles}
                setTitles={setTitles}
                rank={rank}
                setRank={setRank}
                descriptions={descriptions}
                setDescriptions={setDescriptions}
                setBase64={setBase64}
                imageFile={image}
              />
            </DialogContent>
            <DialogActions style={{ paddingTop: tokens.spacingVerticalL }}>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={() => onOpenChange(false)}>
                  {t("previous")}
                </Button>
              </DialogTrigger>
              <Button appearance="primary" type="submit">
                {t("matchs.save")}
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};

export const EditForm = ({
  titles,
  setTitles,
  rank,
  setRank,
  descriptions,
  setDescriptions,
  setBase64,
  imageFile,
}: {
  titles: Record<string, string>;
  setTitles: Dispatch<SetStateAction<Record<string, string>>>;
  rank?: number;
  setRank: Dispatch<SetStateAction<number | undefined>>;
  descriptions: Record<string, string>;
  setDescriptions: Dispatch<SetStateAction<Record<string, string>>>;
  setBase64: Dispatch<SetStateAction<string | undefined>>;
  imageFile?: string;
}) => {
  const { t } = useTranslation();
  const { lang } = useLanguage();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const { dropZone } = useGiftStyle();

  useEffect(() => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Url = event.target?.result as string;
        if (base64Url) {
          const base64 = base64Url.split(",")[1];
          setBase64(base64);
        }
      };
      reader.readAsDataURL(file);
    }
  }, [acceptedFiles, setBase64]);

  return (
    <>
      <FormInput
        label={t("register.giftName")}
        name="name"
        required
        value={titles[lang]}
        onChange={(val) => setTitles((titles) => ({ ...titles, [lang]: val }))}
      />
      <FormInput
        label={t("register.giftRank")}
        type="number"
        name="rank"
        required
        value={`${rank ?? 0}`}
        onChange={(val) => setRank(Number(val))}
      />
      <Label
        size="large"
        style={{
          paddingBottom: tokens.spacingVerticalS,
          paddingTop: tokens.spacingVerticalM,
        }}
      >
        {t("register.giftDescription")}
      </Label>
      <Textarea
        name="description"
        value={descriptions[lang] ?? ""}
        onChange={(_, { value }) =>
          setDescriptions((state) => ({
            ...state,
            [lang]: value,
          }))
        }
      />
      <Label
        size="large"
        style={{
          paddingBottom: tokens.spacingVerticalS,
          paddingTop: tokens.spacingVerticalM,
        }}
        required
      >
        {t("register.giftImage")}
      </Label>
      <div {...getRootProps()} className={dropZone}>
        {!imageFile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalXL}`,
            }}
          >
            <ImageRegular fontSize={30} />
            <Text align="center">{t("register.giftDropzone")}</Text>
          </div>
        )}
        {imageFile && <BackupImage url={imageFile} alt="Image" fit="contain" />}
        <input {...getInputProps()} />
      </div>
    </>
  );
};
