import {
  tokens,
  Text,
  makeStyles,
  mergeClasses,
} from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Match, MatchStatus } from "../../../type";
import { getMatchStatus } from "../../../utils";
import { getPointsColor } from "./pointsUtils";

const useMobileStyle = makeStyles({
  score: {
    borderBottomLeftRadius: tokens.borderRadiusLarge,
  },
  points: {
    borderBottomRightRadius: tokens.borderRadiusLarge,
  },
  background: {
    backgroundColor: tokens.colorBrandBackground2Hover,
    width: "100%",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const getPointsText = (
  status: MatchStatus,
  points: number,
  pointsMax: number
): [string, string] => {
  const color = getPointsColor({ status, points, pointsMax });
  switch (status) {
    case MatchStatus.InProgress:
    case MatchStatus.Planned:
      return [`${points}/${pointsMax} Pts`, color];
    default:
      return [`+${points}/${pointsMax} Pts`, color];
  }
};

export function MobilePreview({ match }: { match: Match }) {
  const status = getMatchStatus(match);
  const { score, points, background } = useMobileStyle();
  const { t } = useTranslation();
  const [scoreText, setScoreText] = useState("");

  const getTextFromStatus = useCallback(
    (status: MatchStatus, group: string) => {
      if (status === MatchStatus.Ended) {
        return t("matchs.finalScore");
      } else if (status === MatchStatus.InProgress) {
        return t("matchs.currentScore");
      } else {
        return group;
      }
    },
    [t]
  );

  useEffect(() => {
    switch (status) {
      case MatchStatus.Planned:
        setScoreText("");
        break;
      case MatchStatus.InProgress:
      case MatchStatus.Ended: {
        const left = match.score[0].penalties
          ? `(${match.score[0].penalties}) ${match.score[0].normal}`
          : match.score[0].normal;
        const right = match.score[1].penalties
          ? `${match.score[1].normal} (${match.score[1].penalties})`
          : match.score[1].normal;
        setScoreText(`${left} - ${right}`);
      }
    }
  }, [status, match.score]);

  const statusText = getTextFromStatus(status, match.group ?? "");
  const [pointText, pointColor] = getPointsText(
    status,
    match.points ?? 0,
    match.pointsMax
  );

  return (
    <>
      <span
        style={{
          width: "100%",
          marginTop: tokens.spacingVerticalS,
        }}
      >
        <Text className={mergeClasses(score, background)}>{statusText}</Text>
      </span>

      <Text
        align="center"
        className={background}
        style={{ marginTop: tokens.spacingVerticalS }}
      >
        {scoreText}
      </Text>
      <span
        style={{
          width: "100%",
          marginTop: tokens.spacingVerticalS,
        }}
      >
        <Text
          className={mergeClasses(points, background)}
          align="center"
          weight="semibold"
          style={{ color: pointColor }}
        >
          {pointText}
        </Text>
      </span>
    </>
  );
}
