import {
  Button,
  makeStyles,
  Subtitle2Stronger,
  tokens,
} from "@fluentui/react-components";
import { ArrowLeft24Filled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MatchStatus } from "../../../type";
import { LAYOUT_SHIFT, MOBILE_SHIFT } from "../../pageStyle";
import { Points } from "../MatchPreview/Points";

const useMobileStyle = makeStyles({
  mobile: {
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "inherit",
    },
  },
  backButton: {
    height: "32px",
    position: "absolute",
    left: tokens.spacingHorizontalMNudge,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      left: tokens.spacingHorizontalNone,
    },
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      top: tokens.spacingVerticalL,
    },
    top: tokens.spacingVerticalNone,
  },
});

export function DetailsHeader({
  title,
  points,
  status,
  pointsMax,
}: {
  title?: string;
  points: number;
  status: MatchStatus;
  pointsMax: number;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mobile, backButton } = useMobileStyle();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "96px 1fr 96px",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <Button
        icon={<ArrowLeft24Filled />}
        onClick={() => {
          navigate("/pronostics");
        }}
        appearance="primary"
        className={backButton}
      >
        {t("previous")}
      </Button>
      <Subtitle2Stronger
        align="center"
        style={{ color: tokens.colorBrandBackground, gridColumnStart: 2 }}
      >
        {title}
      </Subtitle2Stronger>
      <span
        style={{ paddingRight: tokens.spacingHorizontalL, justifySelf: "end" }}
      >
        <span className={mobile}>
          <Points points={points} status={status} pointsMax={pointsMax} />
        </span>
      </span>
    </div>
  );
}
