import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useMainStyle = makeStyles({
  pronoContainer: {
    paddingTop: tokens.spacingVerticalXXL,
    display: "flex",
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  noPadding: {
    ...shorthands.padding(tokens.spacingVerticalNone),
  },
  tabs: {
    alignItems: "center",
    display: "flex",
  },
  primaryButton: {
    backgroundColor: tokens.colorBrandForegroundOnLight,
    ...shorthands.margin(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalXXL
    ),
  },
  page: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
});

export const useRightPart = makeStyles({
  container: {
    width: "430px",
    paddingTop: tokens.spacingVerticalXXL,
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
  searchBar: {
    flexGrow: 1,
    maxWidth: "100%",
    opacity: 1,
    "& > span": {
      "& > span[role=button]": {
        display: "none",
      },
    },
  },
  searchWrapper: {
    ...shorthands.padding(
      tokens.spacingVerticalMNudge,
      tokens.spacingHorizontalNone,
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalXL
    ),
    opacity: 1,
  },
});
