import { shorthands, tokens } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
export const LAYOUT_SHIFT = "1258px";
export const MOBILE_SHIFT = "850px";

const paddingSide = tokens.spacingHorizontalM;

export const usePageStyle = makeStyles({
  layout: {
    display: "grid",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridTemplateAreas: `
                        "header   header"
                        "main     aside"`,
      gridTemplateColumns: "1fr 450px",
    },
    gridTemplateAreas: `"header" 
                        "main"`,
    gridTemplateColumns: "1fr",
    ...shorthands.padding(0, 0, paddingSide, 0),
    height: "100vh",
    width: "calc(100vw - 5px)",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      ...shorthands.padding(0, 0, paddingSide, paddingSide),
    },
    overflowY: "hidden",
  },
  layoutHeaderFull: {
    gridTemplateRows: "52px 1fr",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridTemplateRows: "208px 1fr",
    },
  },
  layoutHeaderReduced: {
    gridTemplateRows: "52px 1fr",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridTemplateRows: "50px 1fr",
    },
  },
  headerFull: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      justifyContent: "space-between",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
    },
  },
  header: {
    display: "flex",
    width: "100%",
    position: "relative",
    ...shorthands.gridArea("header"),
  },
  main: {
    ...shorthands.padding(
      tokens.spacingVerticalS,
      0,
      0,
      tokens.spacingHorizontalXS
    ),
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      ...shorthands.gridArea("main"),
      overflowY: "hidden",
      ...shorthands.padding(0, 0, 0, 0),
    },
    height: `calc(100vh - 52px - ${tokens.spacingVerticalM})`,
  },
  mainWithoutHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      maxHeight: `calc(100vh - 50px - ${tokens.spacingHorizontalM})`,
    },
  },
  mainHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      maxHeight: `calc(100vh - 208px - ${tokens.spacingHorizontalM})`,
    },
  },
  aside: {
    gridTemplateRows: "1fr 88px",
    alignSelf: "stretch",
    overflowY: "hidden",
    display: "grid",
  },
  divider: {
    flexGrow: 0,
    minHeight: "1px",
  },
});
