import { tokens, Text } from "@fluentui/react-components";
import { FlagWithBackground } from "../FlagWithBackground";

export function TeamHeader({
  teamName,
  imageUrl,
  side = "left",
}: {
  teamName: string;
  imageUrl: string;
  side?: "left" | "right";
}) {
  return (
    <span
      style={{
        justifySelf: side === "right" ? "end" : "start",
        display: "flex",
        alignItems: "center",
        gap: tokens.spacingHorizontalL,
        flexDirection: side === "right" ? "row-reverse" : "row",
      }}
    >
      <FlagWithBackground url={imageUrl} size={24} />
      <Text size={300} weight="bold">
        {teamName}
      </Text>
    </span>
  );
}
