import { MatchDetailType, MatchResponseType } from "@winnerscore/common";

export enum MatchSelected {
  All = "all",
  Coming = "next",
  Current = "current",
  Finished = "finished",
}

export const isMatchSelected = (value: unknown): value is MatchSelected => {
  if (typeof value !== "string") {
    return false;
  }
  return Object.values<string>(MatchSelected).includes(value);
};

export type StartedMatch = MatchResponseType[0] & {
  started: true;
  score: [
    { normal: number; penalties: number | null },
    { normal: number; penalties: number | null },
  ];
};

export type NotStartedMatch = MatchResponseType[0] & {
  started: false;
};

export type EventType = "Goal" | "Card" | "subst" | "Var";

export type MatchEvent = {
  time: {
    elapsed: number;
    extra: null | number;
  };
  team: number;
  player: {
    id: number;
    name: string;
  };
  type: EventType;
  detail: string;
};

export type Team = {
  id: number;
  logo: string;
  name: string;
};

export type Match = StartedMatch | NotStartedMatch;

export type MatchPage = MatchDetailType & { started: boolean };

export enum MatchStatus {
  Planned,
  Ended,
  InProgress,
}

export enum TabValue {
  Chat,
  Players,
  Stats,
}

export type GiftDetail = {
  id: number | string;
  rank: number;
  image_file: string;
  titles: Record<string, string>;
  descriptions: Record<string, string>;
};
