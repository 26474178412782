import { useMediaQuery } from "usehooks-ts";
import { LAYOUT_SHIFT } from "../pageStyle";
import { Pronostics } from "./MatchPreview/Pronostics";
import { Ranking } from "./Ranking/Ranking";

export function PronosticsPage() {
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  return (
    <>
      <Pronostics />
      {desktop && <Ranking />}
    </>
  );
}
