import { CompetitionType } from "@winnerscore/common";
import { useTimeFormatter } from "./useTimeFormatter";
import {
  Image,
  Text,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import Gold from "./gold.png";
import Silver from "./silver.png";
import Bronze from "./bronze.png";
import { useTranslation } from "react-i18next";
import { CountrySelect } from "../../question/CountrySelect";
import { LAYOUT_SHIFT } from "../../pageStyle";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCountries } from "../../../api";
import { CountryFlag } from "./CountryFlag";
import { useMediaQuery } from "usehooks-ts";

const useCompetitionStyle = makeStyles({
  mobile: {
    display: "flex",
    justifyContent: "center",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "none",
    },
  },
  competition: {
    display: "grid",
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    rowGap: tokens.spacingVerticalS,
    justifyItems: "center",
    paddingBottom: tokens.spacingVerticalM,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      justifyItems: "start",
      gridTemplateColumns: "repeat(3, minmax(0px, 1fr)) 0.5fr",
    },
  },
  question: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridColumnStart: 2,
      gridColumnEnd: 4,
      justifySelf: "start",
      paddingTop: tokens.spacingVerticalS,
    },
  },
  countryRow: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridColumnStart: 1,
      gridColumnEnd: 4,
      display: "grid",
      columnGap: tokens.spacingHorizontalS,
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      justifySelf: "stretch",
      justifyItems: "center",
    },
  },
  questionRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      flexDirection: "row",
      columnGap: tokens.spacingHorizontalL,
      gridColumnStart: 1,
      gridColumnEnd: 5,
    },
  },
  points: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0px, 1fr)) 0.5fr",
    backgroundColor: "rgba(75, 83, 188, 0.1)",
    width: "100%",
    ...shorthands.padding(tokens.spacingHorizontalS),
    borderBottomLeftRadius: tokens.borderRadiusLarge,
    borderBottomRightRadius: tokens.borderRadiusLarge,
    justifyContent: "space-between",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "flex",
      backgroundColor: "inherit",
      justifyContent: "center",
    },
  },
  mobilePoints: {
    gridColumnStart: 4,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "none",
    },
  },
});

export const Competition = ({
  competition,
}: {
  competition: CompetitionType;
}) => {
  const { startDate } = useTimeFormatter(competition.date);
  const { t } = useTranslation();
  const {
    mobile,
    competition: cmpClass,
    question,
    countryRow,
    questionRow,
    points,
    mobilePoints,
  } = useCompetitionStyle();

  const [selection, setSelection] = useState({
    gold: competition.pronostic?.gold,
    silver: competition.pronostic?.silver,
    bronze: competition.pronostic?.bronze,
  });

  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);

  const { data } = useQuery({
    queryKey: ["countries", competition.id],
    queryFn: async () => {
      const countries = getCountries(competition.id);
      return countries;
    },
  });

  return (
    <div className={cmpClass}>
      <div className={questionRow}>
        <Text
          style={{
            paddingTop: tokens.spacingVerticalS,
          }}
        >
          {startDate}
        </Text>
        <Text className={question}>{competition.question}</Text>
      </div>

      <div className={countryRow}>
        <div className={mobile}>
          <div
            style={{
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
              padding: tokens.spacingVerticalS,
            }}
          >
            <Image src={Gold} className={mobile} />
            <Text
              weight="semibold"
              style={{ color: "rgba(224, 159, 0, 1)" }}
              className={mobile}
            >
              {t("olympics.gold")}
            </Text>
          </div>
        </div>
        {competition.gold ? (
          <CountryFlag
            flag={competition.gold.flag}
            name={competition.gold.name}
          />
        ) : (
          <CountrySelect
            selected={selection.gold ?? undefined}
            onSelect={(country) => {
              setSelection({
                gold: country,
                silver: selection.silver,
                bronze: selection.bronze,
              });
            }}
            countries={data ?? []}
          />
        )}
        <div className={mobile}>
          <div
            style={{
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
              padding: tokens.spacingVerticalS,
            }}
          >
            <Image src={Silver} />
            <Text
              weight="semibold"
              style={{ color: "rgba(132, 132, 132, 1)" }}
              className={mobile}
            >
              {t("olympics.silver")}
            </Text>
          </div>
        </div>
        {competition.silver ? (
          <CountryFlag
            name={competition.silver.name}
            flag={competition.silver.flag}
          />
        ) : (
          <CountrySelect
            selected={selection.silver ?? undefined}
            onSelect={(country) =>
              setSelection({
                gold: selection.gold,
                silver: country,
                bronze: selection.bronze,
              })
            }
            countries={data ?? []}
          />
        )}
        <div className={mobile}>
          <div
            style={{
              display: "flex",
              columnGap: tokens.spacingHorizontalS,
              alignItems: "center",
              padding: tokens.spacingVerticalS,
            }}
          >
            <Image src={Bronze} className={mobile} />
            <Text
              weight="semibold"
              style={{ color: "rgba(120, 61, 1, 1)" }}
              className={mobile}
            >
              {t("olympics.bronze")}
            </Text>
          </div>
        </div>
        {competition.bronze ? (
          <CountryFlag
            name={competition.bronze.name}
            flag={competition.bronze.flag}
          />
        ) : (
          <CountrySelect
            selected={selection.bronze ?? undefined}
            onSelect={(country) =>
              setSelection({
                gold: selection.gold,
                silver: selection.silver,
                bronze: country,
              })
            }
            countries={data ?? []}
          />
        )}
      </div>
      <div className={points}>
        <Text>{desktop ? competition.points : "Points"}</Text>
        <Text align="center" className={mobilePoints}>
          {competition.points}
        </Text>
      </div>
    </div>
  );
};
