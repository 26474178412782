import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Match, MatchPage } from "../../../type";
import { Resume } from "./Resume";
import { Scorers } from "./Scorers";
import { InfoLine } from "./InfoLine";
import { LAYOUT_SHIFT } from "../../pageStyle";
import { MobilePreview } from "../MatchPreview/MobilePreview";
import { useMediaQuery } from "usehooks-ts";

const useScoreStyle = makeStyles({
  details: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) auto minmax(0, 1fr)",
    backgroundColor: tokens.colorNeutralBackground1,
    paddingTop: tokens.spacingVerticalM,
    ...shorthands.borderRadius(
      tokens.borderRadiusLarge,
      tokens.borderRadiusLarge,
      0,
      0
    ),
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      backgroundColor: tokens.colorNeutralBackground3,
    },
  },
});

export function DetailGeneral({ match }: { match: MatchPage }) {
  const { details } = useScoreStyle();
  const mobile = useMediaQuery(`(max-width: ${LAYOUT_SHIFT})`);
  return (
    <div className={details}>
      <Scorers match={match} />
      <Resume match={match} />
      <Scorers match={match} side="right" />
      {mobile && <MobilePreview match={match as Match} />}
      <InfoLine
        startDate={match.start_date}
        referee={match.referee}
        stadium={match.stadium}
      />
    </div>
  );
}
