import { Text, tokens } from "@fluentui/react-components";

export function Progress({
  percent,
  valueLeft,
  valueRight,
}: {
  percent: number;
  valueLeft: string;
  valueRight: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        padding: `0 ${tokens.spacingHorizontalSNudge}`,
        alignItems: "center",
        columnGap: tokens.spacingHorizontalMNudge,
      }}
    >
      <Text
        style={{
          width: "30px",
          flexShrink: 0,
          color: tokens.colorNeutralForeground3BrandHover,
        }}
        weight="semibold"
      >
        {valueLeft}
      </Text>
      <div
        style={{
          overflow: "hidden",
          borderRadius: tokens.borderRadiusCircular,
          width: "100%",
          height: "7px",
          backgroundColor: tokens.colorNeutralStroke1Hover,
        }}
      >
        <div
          style={{
            width: `${Math.max(0, Math.min(100, percent * 100))}%`,
            borderRadius: tokens.borderRadiusCircular,
            backgroundColor: tokens.colorNeutralForeground3BrandHover,
            height: "7px",
          }}
        ></div>
      </div>
      <Text
        style={{ width: "30px", flexShrink: 0 }}
        align="end"
        weight="semibold"
      >
        {valueRight}
      </Text>
    </div>
  );
}
