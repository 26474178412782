import {
  Spinner,
  Text,
  Button,
  tokens,
  Image,
  makeStyles,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { getCompetitions, getMatchs } from "../../../api";
import { usePronoMatch } from "../../../store";
import { MATCH_KEY, Olympics } from "../../../utils";
import { MatchList } from "./MatchList";
import { Match } from "../../../type";
import { Competitions } from "./CompetitionList";
import { CompetitionType } from "@winnerscore/common";
import Gold from "./gold.png";
import Silver from "./silver.png";
import Bronze from "./bronze.png";
import { useTranslation } from "react-i18next";
import { LAYOUT_SHIFT } from "../../pageStyle";

const useStyle = makeStyles({
  medals: {
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: Olympics ? "grid" : "none",
      gridTemplateColumns: "repeat(3, minmax(0, 1fr)) 0.5fr",
      justifyItems: "center",
    },
  },
});

export function PronosticsList() {
  const { displayMatchs, setMatchs } = usePronoMatch();
  const { t } = useTranslation();
  const { medals } = useStyle();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [MATCH_KEY],
    queryFn: async () => {
      if (Olympics) {
        return await getCompetitions();
      } else {
        return await getMatchs();
      }
    },
  });

  useEffect(() => {
    if (data) {
      setMatchs(data);
    }
  }, [data, setMatchs]);

  return (
    <>
      <div className={medals}>
        <div
          style={{
            display: "flex",
            columnGap: tokens.spacingHorizontalS,
            alignItems: "center",
            padding: tokens.spacingVerticalS,
          }}
        >
          <Image src={Gold} />
          <Text weight="semibold" style={{ color: "rgba(224, 159, 0, 1)" }}>
            {t("olympics.gold")}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            columnGap: tokens.spacingHorizontalS,
            alignItems: "center",
            padding: tokens.spacingVerticalS,
          }}
        >
          <Image src={Silver} />
          <Text weight="semibold" style={{ color: "rgba(132, 132, 132, 1)" }}>
            {t("olympics.silver")}
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            columnGap: tokens.spacingHorizontalS,
            alignItems: "center",
            padding: tokens.spacingVerticalS,
          }}
        >
          <Image src={Bronze} />
          <Text weight="semibold" style={{ color: "rgba(120, 61, 1, 1)" }}>
            {t("olympics.bronze")}
          </Text>
        </div>
      </div>
      <div
        style={{
          overflowY: "auto",
          maxHeight: `calc(100vh - 98px${Olympics ? " - 40px" : ""})`,
          width: "100%",
        }}
      >
        {isLoading && <Spinner size="extra-large" label="Loading matchs" />}
        {data && !isLoading && !process.env.REACT_APP_OLYMPICS && (
          <MatchList matchs={displayMatchs as Match[]} />
        )}
        {data && !isLoading && process.env.REACT_APP_OLYMPICS && (
          <Competitions competitions={displayMatchs as CompetitionType[]} />
        )}
        {isError && !data?.length && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text style={{ padding: tokens.spacingVerticalXXL }}>
              Error loading the matchs
            </Text>
            <Button
              appearance="primary"
              style={{ width: "100px" }}
              onClick={() => {
                void refetch();
              }}
            >
              Reload
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
