import {
  Caption1Stronger,
  Subtitle2,
  Tree,
  TreeItem,
  TreeItemLayout,
  Text,
  makeStyles,
  tokens,
  shorthands,
  Button,
  Image,
  Spinner,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LAYOUT_SHIFT } from "../pageStyle";
import { useQuery } from "@tanstack/react-query";
import { getFaq } from "../../api";

const useFaqStyle = makeStyles({
  titleRow: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground2Hover,
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
    color: tokens.colorBrandForegroundOnLight,
    ...shorthands.margin(
      tokens.spacingVerticalXS,
      tokens.spacingHorizontalNone
    ),
  },
  title: {
    columnGap: tokens.spacingHorizontalM,
    display: "flex",
  },
  tree: {
    ...shorthands.padding(
      tokens.spacingVerticalL,
      tokens.spacingHorizontalL,
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalNone
    ),
  },
  container: {
    paddingLeft: tokens.spacingHorizontalXL,
    flexGrow: 1,
    paddingTop: tokens.spacingVerticalXL,
  },
  back: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "none",
    },
    justifySelf: "start",
    marginLeft: tokens.spacingHorizontalXL,
    marginBottom: tokens.spacingVerticalM,
  },
  margin: {
    width: "100%",
    marginTop: tokens.spacingVerticalL,
    overflowY: "auto",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      marginTop: tokens.spacingVerticalNone,
    },
  },
});

export function Faq() {
  const { titleRow, title, tree, container, back, margin } = useFaqStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["FAQ_KEY"],
    queryFn: getFaq,
  });

  return (
    <div className={margin}>
      <Button
        className={back}
        appearance="primary"
        onClick={() => {
          navigate("/rules");
        }}
      >
        {t("previous")}
      </Button>
      <div className={container}>
        <Text size={400} weight="bold">
          {t("faq.faq")}
        </Text>
        <Tree aria-label="FAQ" className={tree}>
          {data?.map((node, i) => {
            return (
              <TreeItem itemType="branch" key={i}>
                <TreeItemLayout className={titleRow}>
                  <span className={title}>
                    {node.logo && (
                      <Image src={`data:image/jpeg;base64,${node.logo}`} />
                    )}
                    <Subtitle2>{node.title}</Subtitle2>
                  </span>
                </TreeItemLayout>
                <Tree>
                  {node.questions.map((question, i) => {
                    return (
                      <TreeItem itemType="branch" key={i}>
                        <TreeItemLayout>
                          <Caption1Stronger>{question.title}</Caption1Stronger>
                        </TreeItemLayout>
                        <Tree>
                          <TreeItem itemType="leaf">
                            <TreeItemLayout>
                              <Text>{question.answer}</Text>
                            </TreeItemLayout>
                          </TreeItem>
                        </Tree>
                      </TreeItem>
                    );
                  })}
                </Tree>
              </TreeItem>
            );
          })}
          {isLoading && <Spinner />}
        </Tree>
      </div>
    </div>
  );
}
