import {
  CreateUserType,
  MatchDetailType,
  MatchResponseType,
  PronoResponseType,
  PronoResponseTypeMixed,
  RankingResponseType,
  GiftType,
  RankingDetailReponseType,
  CompetitionType,
} from "@winnerscore/common";
import { GiftDetail, Match, MatchEvent, MatchPage } from "./type";
import { authFetch } from "./utils";

const SEVEN_MINUTES = 7 * 60 * 1000;

export const getCurrentUser = async () => {
  const response = await authFetch(
    process.env.REACT_APP_BACKEND_URL + "/current-user"
  );
  return response as RankingDetailReponseType["users"][0];
};

export const getMatchs = async (): Promise<Match[]> => {
  const serverResponse = (await authFetch(
    process.env.REACT_APP_BACKEND_URL + "/match"
  )) as MatchResponseType;
  const now = Date.now();
  return serverResponse.map((m) => {
    if (new Date(m.start_date).getTime() <= now) {
      return {
        ...m,
        started: true,
        team_a: {
          ...m.team_a,
          image: `${process.env.REACT_APP_BACKEND_URL}/flag/${m.team_a.image}`,
        },
        team_b: {
          ...m.team_b,
          image: `${process.env.REACT_APP_BACKEND_URL}/flag/${m.team_b.image}`,
        },
        score: [m.score[0], m.score[1]],
      };
    } else {
      return {
        ...m,
        started: false,
        team_a: {
          ...m.team_a,
          image: `${process.env.REACT_APP_BACKEND_URL}/flag/${m.team_a.image}`,
        },
        team_b: {
          ...m.team_b,
          image: `${process.env.REACT_APP_BACKEND_URL}/flag/${m.team_b.image}`,
        },
      };
    }
  });
};

export const getMatchDetail = async (
  matchId: number
): Promise<MatchPage | undefined> => {
  const serverResponse = await authFetch<MatchDetailType>(
    `${process.env.REACT_APP_BACKEND_URL}/match/${matchId}`
  );
  const now = Date.now();
  if (
    serverResponse &&
    new Date(serverResponse.start_date).getTime() <= now - SEVEN_MINUTES
  ) {
    return {
      ...serverResponse,
      started: true,
      team_a: {
        ...serverResponse.team_a,
        image: `${process.env.REACT_APP_BACKEND_URL}/flag/${serverResponse.team_a.image}`,
      },
      team_b: {
        ...serverResponse.team_b,
        image: `${process.env.REACT_APP_BACKEND_URL}/flag/${serverResponse.team_b.image}`,
      },
    };
  } else if (serverResponse) {
    return {
      ...serverResponse,
      started: false,
      team_a: {
        ...serverResponse.team_a,
        image: `${process.env.REACT_APP_BACKEND_URL}/flag/${serverResponse.team_a.image}`,
      },
      team_b: {
        ...serverResponse.team_b,
        image: `${process.env.REACT_APP_BACKEND_URL}/flag/${serverResponse.team_b.image}`,
      },
    };
  }
};

export const getEvents = async (matchId: number) => {
  const data = await authFetch(
    `${process.env.REACT_APP_BACKEND_URL}/${matchId}/events`
  );
  return data as MatchEvent[];
};

export const getRankingPagination = async (page: number) => {
  const data = await authFetch(
    `${process.env.REACT_APP_BACKEND_URL}/ranking?page=${page}`
  );
  return data as RankingResponseType;
};

export const setProno = async ({
  matchId,
  prono,
}: {
  matchId: number;
  prono: [number, number];
}) => {
  const response = await authFetch<PronoResponseType>(
    `${process.env.REACT_APP_BACKEND_URL}/set-prono`,
    {
      method: "POST",
      body: JSON.stringify({ matchId, prono }),
    }
  );
  return response;
};

export const getProno = async (matchId: number) => {
  const data = await authFetch<PronoResponseTypeMixed>(
    `${process.env.REACT_APP_BACKEND_URL}/prono/${matchId}`
  );
  return data;
};

export const createUser = async (user: CreateUserType) => {
  const response = await authFetch<{ created: boolean }>(
    `${process.env.REACT_APP_BACKEND_URL}/user/${user.id}`,
    { method: "POST", body: JSON.stringify(user) }
  );
  return response;
};

export const companyExist = async (companyId: string) => {
  const exist = await authFetch<boolean>(
    `${process.env.REACT_APP_BACKEND_URL}/company/${companyId}`
  );

  return exist;
};

export const getTipsters = async () => {
  const response = await authFetch<{ tipsters: number }>(
    `${process.env.REACT_APP_BACKEND_URL}/tipsters`
  );
  return response?.tipsters;
};

export const getGifts = async () => {
  const response = await authFetch<GiftType[]>(
    `${process.env.REACT_APP_BACKEND_URL}/gifts`
  );
  response?.forEach((gift) => {
    gift.image_file = `${process.env.REACT_APP_BACKEND_URL}/${gift.image_file}`;
  });
  return response;
};

export const getCompetitions = async () => {
  const response = await authFetch<CompetitionType[]>(
    `${process.env.REACT_APP_BACKEND_URL}/list-competitions`
  );
  response?.forEach((competition) => {
    if (competition.gold) {
      competition.gold.flag = `${process.env.REACT_APP_BACKEND_URL}/flag/${competition.gold.flag}`;
    }
    if (competition.silver) {
      competition.silver.flag = `${process.env.REACT_APP_BACKEND_URL}/flag/${competition.silver.flag}`;
    }
    if (competition.bronze) {
      competition.bronze.flag = `${process.env.REACT_APP_BACKEND_URL}/flag/${competition.bronze.flag}`;
    }
  });
  return response;
};

export const getCountries = async (competitionId: number) => {
  const response = await authFetch<
    { name: string; id: number; flag: string }[]
  >(`${process.env.REACT_APP_BACKEND_URL}/list-countries`, {
    searchParams: new URLSearchParams({ competitionId: `${competitionId}` }),
    method: "GET",
  });
  response?.forEach((flag) => {
    flag.flag = `${process.env.REACT_APP_BACKEND_URL}/flag/${flag.flag}`;
  });
  return response;
};

export const setOlympicsPronostic = async ({
  competitionId,
  gold,
  silver,
  bronze,
}: {
  competitionId: number;
  gold: number | null;
  silver: number | null;
  bronze: number | null;
}) => {
  const response = await authFetch<void>(
    `${process.env.REACT_APP_BACKEND_URL}/set-pronostic`,
    {
      method: "POST",
      body: JSON.stringify({
        competitionId,
        gold,
        silver,
        bronze,
      }),
    }
  );
  return response;
};

export const checkNumberUsers = async () => {
  const response = await authFetch<boolean>(
    `${process.env.REACT_APP_BACKEND_URL}/allowed`
  );
  return response;
};

type Faq = {
  id: number;
  logo?: string;
  title: string;
  order: number;
  questions: { answer: string; order: number; title: string }[];
};

export const getFaq = async () => {
  const response = await authFetch<Faq[]>(
    `${process.env.REACT_APP_BACKEND_URL}/faq`
  );

  return response;
};

type Settings = {
  id: number;
  header: string;
  logo: string | null;
  title: string | null;
  date: string | null;
  subtitle: string | null;
};

export const getSettings = async () => {
  const data = await authFetch<Settings>(
    `${process.env.REACT_APP_BACKEND_URL}/settings`
  );

  return data;
};

export const getRules = async () => {
  const data = await authFetch<{ data: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/rules`
  );
  return data;
};

export const getRegisterGifts = async ({
  companyId,
}: {
  companyId: string;
}) => {
  try {
    const gifts = await authFetch<GiftDetail[]>(
      `${process.env.REACT_APP_BACKEND_URL}/register/gifts/${companyId}`
    );
    return gifts ?? [];
  } catch (_) {
    return [];
  }
};

export const getRegisterRules = async ({
  companyId,
}: {
  companyId: string;
}) => {
  const rules = await authFetch<{ en: string; fr: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/register/rules/${companyId}`
  );
  return rules;
};

export const getAdmins = async ({ companyId }: { companyId: string }) => {
  const admins = await authFetch<
    {
      id: number;
      location: string;
      name: string;
      role: string;
      user_id: string;
    }[]
  >(`${process.env.REACT_APP_BACKEND_URL}/register/admins/${companyId}`);
  return admins;
};

export type RegisterData = {
  company: {
    id: string;
    displayName: string;
    phones: string[];
    postalCode: string;
    city: string;
  };
  rules: Record<string, string>;
  gifts: Record<string, GiftDetail>;
  admins: {
    id: null | number;
    name?: string | null;
    role: string;
    location: string | null;
    user_id: string;
  }[];
};

export const register = async ({
  company,
  rules,
  gifts,
  admins,
}: RegisterData) => {
  await authFetch(`${process.env.REACT_APP_BACKEND_URL}/register/admins`, {
    method: "POST",
    body: JSON.stringify({ company, rules, gifts, admins }),
  });
};

export const companyAccepted = async ({ companyId }: { companyId: string }) => {
  const date = await authFetch<{ date?: string }>(
    `${process.env.REACT_APP_BACKEND_URL}/register/accepted/${companyId}`
  );
  return date;
};
