import {
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Add20Filled } from "@fluentui/react-icons";
import { Balloon } from "./Balloon";
import { useTranslation } from "react-i18next";

const useTableStyle = makeStyles({
  th: {
    display: "flex",
    ...shorthands.padding(
      tokens.spacingVerticalL,
      tokens.spacingHorizontalNone
    ),
    justifyContent: "center",
  },
  tr: {
    backgroundColor: tokens.colorBrandForegroundOnLight,
    ...shorthands.border("1px", "solid", tokens.colorBrandForegroundOnLight),
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
    color: tokens.colorNeutralForeground1,
    breakInside: "avoid-column",
  },
  phase: {
    width: "40%",
    textAlign: "start",
    ...shorthands.padding(
      tokens.spacingHorizontalNone,
      tokens.spacingHorizontalM
    ),
    color: tokens.colorNeutralForegroundStaticInverted,
  },
  tableRow: {
    ...shorthands.border("1px", "solid", tokens.colorNeutralStrokeDisabled),
  },
  firstRow: {
    ...shorthands.borderWidth("0", "1px", "1px", "1px"),
  },
  cellContent: {
    textAlign: "center",
    ...shorthands.padding(
      tokens.spacingVerticalS,
      tokens.spacingHorizontalNone
    ),
  },
});

export function PointExample() {
  const { th, tr, table, phase, tableRow, firstRow, cellContent } =
    useTableStyle();
  const { t } = useTranslation();
  return (
    <table className={table}>
      <thead>
        <tr className={tr}>
          <th className={phase}>
            <Text>Phase</Text>
          </th>
          <th>
            <div className={th}>
              <Add20Filled style={{ color: "red", rotate: "45deg" }} />
            </div>
          </th>
          <th>
            <div className={th}>
              <Balloon color="#000000" />
            </div>
          </th>
          <th>
            <div className={th}>
              <Balloon color="#000000" />
              <Balloon color="#000000" />
              <Balloon color="#000000" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {[...Array(5).keys()].map((i) => {
          return (
            <tr
              key={i}
              className={i === 0 ? mergeClasses(tableRow, firstRow) : tableRow}
            >
              <td style={{ paddingLeft: tokens.spacingHorizontalM }}>
                {t(`rules.example.J${i + 1}`)}
              </td>
              <td className={cellContent}>0</td>
              <td className={cellContent}>1</td>
              <td className={cellContent}>3</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
