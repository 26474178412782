import { CompanyType, CompetitionType } from "@winnerscore/common";
import { create } from "zustand";
import { GiftDetail, Match, MatchSelected, TabValue } from "./type";
import i18n from "i18next";
import { persist } from "zustand/middleware";

const MATCH_DURATION = process.env.REACT_APP_OLYMPICS
  ? 15 * 1000 * 60
  : 105 * 1000 * 60;

const filterMatchs = (
  tab: MatchSelected,
  matchList: Match[] | CompetitionType[]
): Match[] | CompetitionType[] => {
  const now = Date.now();
  switch (tab) {
    case MatchSelected.All:
      return matchList;
    case MatchSelected.Coming: {
      const coming = (matchList as any[]).filter((match) => {
        if ("date" in match) {
          return new Date(match.date).getTime() >= now;
        } else {
          return new Date(match.start_date).getTime() >= now;
        }
      });
      return coming as Match[] | CompetitionType[];
    }
    case MatchSelected.Current: {
      const current = (matchList as any[]).filter((match) => {
        if ("date" in match) {
          const start = new Date(match.date);
          return (
            start.getTime() + MATCH_DURATION > now && now > start.getTime()
          );
        }
        const start = new Date(match.start_date);
        return start.getTime() + MATCH_DURATION > now && now > start.getTime();
      });
      return current as Match[] | CompetitionType[];
    }
    case MatchSelected.Finished: {
      const finished = (matchList as any[]).filter((match) => {
        if ("date" in match) {
          const start = new Date(match.date);
          return start.getTime() + MATCH_DURATION < now;
        }
        const start = new Date(match.start_date);
        return start.getTime() + MATCH_DURATION < now;
      });
      return finished as Match[] | CompetitionType[];
    }
  }
};

export const usePronoMatch = create<{
  tab: MatchSelected;
  setTab: (m: MatchSelected) => void;
  matchs: Match[] | CompetitionType[];
  displayMatchs: Match[] | CompetitionType[];
  setMatchs: (matchs: Match[] | CompetitionType[]) => void;
}>()((set) => ({
  tab: MatchSelected.All,
  matchs: [],
  displayMatchs: [],
  setTab: (match: MatchSelected) => {
    set((state) => {
      const displayMatchs = filterMatchs(match, state.matchs);
      return { displayMatchs, tab: match };
    });
  },
  setMatchs: (matchs: Match[] | CompetitionType[]) => {
    set((state) => {
      const displayMatchs = filterMatchs(state.tab, matchs);
      return { displayMatchs, matchs };
    });
  },
}));

const CGU_KEY = "CGU_AGREEMENT";

export const useCgu = create<{ display: boolean; accept: () => void }>()(
  persist(
    (set, get) => {
      const accept = () => set({ display: false });
      return {
        display: get()?.display ?? false,
        accept,
      };
    },
    { name: CGU_KEY }
  )
);

export const useCurrentUser = create<{
  id: string;
  username: string;
  company: CompanyType;
  set: ({
    id,
    username,
    company,
  }: {
    id: string;
    username: string;
    company: CompanyType;
  }) => void;
}>()(
  persist(
    (set, get) => {
      return {
        id: get()?.id ?? "",
        username: get()?.username ?? "",
        company: get()?.company ?? {
          id: "",
          displayName: "",
          phones: [],
          postalCode: "",
        },
        set: ({ id, username, company }) => {
          set({ id, username, company });
        },
      };
    },
    { name: "userStorage" }
  )
);

export const useLanguage = create<{
  lang: string;
  set: (lang: string) => void;
}>()(
  persist(
    (set, get) => {
      return {
        lang: get()?.lang ?? "en",
        set: (lang: string) => {
          const dbCode = lang.slice(0, 2).toLowerCase();
          i18n.changeLanguage(lang).then(
            () => {
              if (!i18n.exists("winnerscore")) {
                void i18n.changeLanguage("en-us");
                set({ lang: "en" });
              } else {
                set({ lang: dbCode });
              }
            },
            () => {}
          );
        },
      };
    },
    { name: "languageStorage" }
  )
);

export const useTabDetail = create<{
  value: TabValue;
  set: (v: TabValue) => void;
}>()((set) => {
  return {
    value: TabValue.Chat,
    set(v) {
      set({ value: v });
    },
  };
});

export const useDesktopHeader = create<{
  update: () => void;
  header: boolean;
}>()(
  persist(
    (set, get) => {
      return {
        header: get()?.header ?? true,
        update: () => {
          set({ header: !(get()?.header ?? true) });
        },
      };
    },
    { name: "header" }
  )
);

export const useCanUseApplication = create<{
  can: boolean;
  set: (can: boolean) => void;
}>()((set) => {
  return {
    can: true,
    set: (can: boolean) => set({ can }),
  };
});

export const useCompanyExist = create<{
  exist?: boolean;
  set: (exist: boolean) => void;
}>()((set) => {
  return { exist: undefined, set: (exist: boolean) => set({ exist }) };
});

type RegisterState = {
  rules: Record<string, string>;
  gifts: Record<string, GiftDetail>;
};
type Action = {
  setRules: ({ rules, language }: { rules: string; language: string }) => void;
  updateGift: (gift: GiftDetail) => void;
  setAllGifts: (gifts: Record<string, GiftDetail>) => void;
  deleteGift: (id: number | string) => void;
  reset: () => void;
};

const INIT_STATE = {
  rules: {
    en: "",
    fr: "",
  },
  gifts: {},
};

export const useRegisterStore = create<RegisterState & Action>()((set) => {
  return {
    setRules: ({ rules, language }: { rules: string; language: string }) => {
      set((state) => ({
        ...state,
        rules: { ...state.rules, [language]: rules },
      }));
    },
    ...INIT_STATE,
    updateGift: (gift: GiftDetail) => {
      set((state) => ({
        ...state,
        gifts: { ...state.gifts, [gift.id]: gift },
      }));
    },
    setAllGifts: (gifts) => {
      set({ gifts });
    },
    deleteGift: (id) => {
      set((state) => {
        const currentGifts = state.gifts;
        delete currentGifts[id];
        return {
          ...state,
          gifts: currentGifts,
        };
      });
    },
    reset: () => {
      set(INIT_STATE);
    },
  };
});
