import { Providers } from "@microsoft/mgt-react";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { TeamsUserCredential } from "@microsoft/teamsfx";

import { TeamsUserCredentialAuthConfig } from "@microsoft/teamsfx";

export const authConfig: TeamsUserCredentialAuthConfig = {
  clientId: process.env.REACT_APP_CLIENT_ID!,
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
};

export const scopes = [
  "User.Read",
  "User.ReadBasic.All",
  "Presence.Read.All",
  "People.Read",
];
export const credential = new TeamsUserCredential(authConfig);
export const provider = new TeamsFxProvider(credential, scopes);
Providers.globalProvider = provider;
