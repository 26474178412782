import { useEffect, useState } from "react";

const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

export const useTimeFormatter = (matchStart: string) => {
  const [time, setTime] = useState<Date>(new Date());
  const [countDown, setCountDown] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string>("");

  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    const matchDate = new Date(matchStart);
    const now = Date.now();
    const start = matchDate.getTime();
    if (start - now > 0 && start - now <= DAY_MILLISECONDS) {
      const msDifference = start - now;
      const hours = Math.floor(msDifference / 36e5);
      const minutes = Math.floor(msDifference / (60 * 1000)) - 60 * hours;
      const seconds = (
        Math.floor(msDifference / 1000) -
        minutes * 60 -
        hours * 60 * 60
      )
        .toFixed(0)
        .padStart(2, "0");
      setCountDown(
        `${hours.toFixed(0).padStart(2, "0")}h:${minutes
          .toFixed(0)
          .padStart(2, "0")}min:${seconds}s`
      );
    } else {
      setCountDown(undefined);
    }
    setStartDate(
      `${matchDate.toLocaleString("default", {
        day: "2-digit",
        month: "short",
      })} | ${matchDate.toLocaleString("default", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })}`
    );
  }, [time, matchStart]);

  return {
    time,
    countDown,
    startDate,
  };
};
