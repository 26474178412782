import {
  makeStyles,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MatchStatus, StartedMatch } from "../../../type";
import { MOBILE_SHIFT } from "../../pageStyle";

const useStyle = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    alignSelf: "start",
  },
  pronostics: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
  },
  score: {
    display: "none",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  scoreText: {
    fontSize: tokens.fontSizeBase600,
    [`@media (min-width: 350px)`]: {
      width: "40px",
    },
    width: "30px",
  },
  separator: {
    fontSize: tokens.fontSizeBase400,
    [`@media (min-width: 350px)`]: {
      width: "40px",
    },
    width: "30px",
  },
});

export function Score({
  match,
  status,
}: {
  match: StartedMatch;
  status: MatchStatus;
}) {
  const style = useStyle();
  const { t } = useTranslation();
  const live = status === MatchStatus.InProgress;
  const getStatusMessage = useCallback(
    (status: MatchStatus) => {
      switch (status) {
        case MatchStatus.Ended:
          return t("matchs.status.over");
        case MatchStatus.InProgress:
          return t("matchs.status.active");
        default:
          return t("matchs.status.notStarted");
      }
    },
    [t]
  );

  const statusMessage = getStatusMessage(status);

  return (
    <div className={style.container}>
      <Text align="center" weight="semibold">
        {statusMessage}
      </Text>
      <div className={style.pronostics}>
        <Text className={style.scoreText} align="center">
          {match.pronostics?.[0]}
        </Text>
        <Text className={style.separator} align="center">
          {" - "}
        </Text>
        <Text className={style.scoreText} align="center">
          {match.pronostics?.[1]}
        </Text>
      </div>
      <div className={style.score}>
        <Text
          size={300}
          weight={live ? "semibold" : "regular"}
          style={{
            color: live
              ? tokens.colorStatusDangerBackground3
              : tokens.colorNeutralForeground1,
          }}
        >
          {status === MatchStatus.Ended
            ? t("matchs.finalScore")
            : t("matchs.currentScore")}
        </Text>
        <div>
          <Text size={300}>
            {match.score[0].penalties
              ? `(${match.score[0].penalties}) ${match.score[0].normal}`
              : match.score[0].normal}
          </Text>
          <Text
            size={300}
            style={{
              padding: `${tokens.spacingVerticalNone} ${tokens.spacingHorizontalS}`,
            }}
          >
            {" - "}
          </Text>
          <Text size={300}>
            {match.score[1].penalties
              ? `${match.score[1].normal} (${match.score[1].penalties})`
              : match.score[1].normal}
          </Text>
        </div>
      </div>
    </div>
  );
}
