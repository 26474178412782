import { Group } from "../MatchPreview/Group";
import {
  Link,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Score } from "./Score";
import { PronoScore } from "./PronoScore";
import { Points } from "./Points";
import { LinkRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { Match } from "../../../type";
import { useMediaQuery } from "usehooks-ts";
import { PronosticTeam } from "./PronosticTeam";
import { MobilePreview } from "./MobilePreview";
import { MOBILE_SHIFT } from "../../pageStyle";
import { getMatchStatus } from "../../../utils";

const useRowStyle = makeStyles({
  row: {
    display: "grid",
    alignSelf: "stretch",
    paddingTop: tokens.spacingVerticalM,
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    marginRight: tokens.spacingHorizontalXS,
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      backgroundColor: tokens.colorNeutralBackground1,
    },
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateColumns:
        "repeat(4, minmax(0, 1fr)) minmax(0, 0.5fr) minmax(0, 0.5fr)",
      marginRight: 0,
    },
    gridTemplateColumns: "minmax(0, 1fr) auto minmax(0, 1fr)",
    "@media (max-width: 849px)": {
      justifyItems: "center",
    },
  },
});

export function MatchRow({ match }: { match: Match }) {
  const navigate = useNavigate();
  const { row } = useRowStyle();
  const medium = useMediaQuery(`(min-width: ${MOBILE_SHIFT})`);
  const mobile = useMediaQuery(`(max-width: calc(${MOBILE_SHIFT} - 1px))`);
  const status = getMatchStatus(match);
  const disabled = match.team_a.known === false || match.team_b.known === false;
  return (
    <div className={row}>
      {medium && (
        <Group timeString={match.start_date} groupName={match.group ?? ""} />
      )}
      <PronosticTeam
        teamName={match.team_a.team_name}
        url={match.team_a.image ?? ""}
        matchId={match.id}
        teamKnown={!disabled}
      />
      {match.started && <Score match={match} status={status} />}
      {!match.started && <PronoScore match={match} />}
      <PronosticTeam
        teamName={match.team_b.team_name}
        url={match.team_b.image ?? ""}
        side="right"
        matchId={match.id}
        teamKnown={!disabled}
      />
      {medium && (
        <>
          <Points
            status={status}
            points={match.points ?? 0}
            pointsMax={match.pointsMax}
          />
          <Link
            onClick={() => {
              navigate(`${match.id}`);
            }}
            style={{
              display: "inline-block",
              width: "20px",
              height: "20px",
              color: tokens.colorNeutralForeground2Link,
              alignSelf: "center",
            }}
            disabled={disabled}
          >
            <LinkRegular style={{ width: "20px", height: "20px" }} />
          </Link>
        </>
      )}
      {mobile && <MobilePreview match={match} />}
    </div>
  );
}
