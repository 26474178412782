import { useTranslation } from "react-i18next";
import {
  Text,
  makeStyles,
  shorthands,
  tokens,
  Divider,
  mergeClasses,
} from "@fluentui/react-components";
import { Rank } from "./Rank";
import { useQuery } from "@tanstack/react-query";
import { CURRENT_USER } from "../../../utils";
import { useCurrentUser } from "../../../store";
import { RankingRow } from "./RankingRow";
import { getCurrentUser } from "../../../api";
import { MOBILE_SHIFT, usePageStyle } from "../../pageStyle";
import { useMediaQuery } from "usehooks-ts";

const usePersonalStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.borderTop(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke2
    ),
    ...shorthands.padding(
      tokens.spacingVerticalSNudge,
      tokens.spacingHorizontalNone,
      tokens.spacingVerticalSNudge,
      tokens.spacingHorizontalXL
    ),
    width: "calc(100% - 0.5rem)",
  },
  rank: {
    display: "grid",
    gridTemplateColumns: "65px 1fr 65px",
    ...shorthands.padding(
      tokens.spacingVerticalS,
      tokens.spacingHorizontalNone
    ),
    "& span": {
      ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    },
  },
  detailColumns: {
    gridTemplateColumns: "65px 1fr 65px",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateColumns: "65px 1fr repeat(4, 65px)",
    },
  },
});

export function Personal({ detail = false }: { detail?: boolean }) {
  const { t } = useTranslation();
  const { rank, detailColumns } = usePersonalStyle();
  const { id } = useCurrentUser();
  const { divider } = usePageStyle();
  const desktop = useMediaQuery(`(min-width: ${MOBILE_SHIFT})`);

  const { data } = useQuery({
    queryKey: [CURRENT_USER],
    queryFn: async () => {
      const data = await getCurrentUser();
      return data;
    },
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Divider className={divider} />
      <div style={{ flexGrow: 1, display: "flex" }}>
        <Divider
          vertical
          className={divider}
          style={{ display: detail ? "none" : "" }}
        />
        <div
          style={{
            paddingLeft: detail ? "0" : tokens.spacingHorizontalL,
            paddingTop: tokens.spacingVerticalS,
            flexGrow: 1,
          }}
        >
          <Text size={400} weight="bold">
            {t("myRank")}
          </Text>
          <div
            className={mergeClasses(rank, detail ? detailColumns : "")}
            style={{ width: "calc(100% - 10px)" }}
          >
            {data && (
              <>
                <span
                  style={{
                    display: "flex",
                    width: "65px",
                    marginRight: tokens.spacingHorizontalS,
                    alignItems: "center",
                  }}
                >
                  <Rank rank={data.rank} change={data.change} />
                </span>
                <RankingRow id={id} name={data.displayname} />
                <Text align="center" style={{ width: "65px" }}>
                  {data.total}
                </Text>
                {desktop && (
                  <>
                    <Text align="center" style={{ width: "65px" }}>
                      {data.perfect ?? 0}
                    </Text>
                    <Text align="center" style={{ width: "65px" }}>
                      {data.good ?? 0}
                    </Text>
                    <Text align="center" style={{ width: "65px" }}>
                      {data.lost ?? 0}
                    </Text>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
