import {
  makeStyles,
  tokens,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { ReactComponent as GroupBackground } from "./GroupBackground.svg";
import { useTimeFormatter } from "./useTimeFormatter";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gridColumnStart: 1,
    height: "100%",
  },
  group: {
    color: tokens.colorNeutralBackground5,
    position: "relative",
  },
  groupText: {
    color: tokens.colorNeutralForeground1,
    position: "absolute",
    top: "50%",
    width: "95%",
    transform: "translateY(-50%)",
    left: tokens.spacingHorizontalS,
  },
  timeClass: {
    ...shorthands.padding(
      tokens.spacingVerticalS,
      tokens.spacingHorizontalNone
    ),
  },
});

export function Group({
  groupName,
  timeString,
}: {
  groupName: string;
  timeString: string;
}) {
  const { container, group, groupText } = useStyle();
  const { startDate, countDown } = useTimeFormatter(timeString);
  return (
    <div className={container}>
      <span className={group}>
        <GroupBackground />
        <Text className={groupText} weight="semibold" size={200}>
          {groupName}
        </Text>
      </span>
      <span
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: tokens.spacingVerticalS,
          paddingLeft: tokens.spacingHorizontalXS,
        }}
      >
        <Text
          style={{ left: "8px", bottom: "10px" }}
          weight="semibold"
          size={200}
        >
          {startDate}
        </Text>
        {countDown && (
          <Text
            style={{ color: tokens.colorStatusDangerBackground3 }}
            size={200}
            weight="semibold"
          >
            {countDown}
          </Text>
        )}
      </span>
    </div>
  );
}
