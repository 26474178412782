import { ReactNode } from "react";
import { Image, tokens } from "@fluentui/react-components";

import Turtle from "./Error.png";
import { useTranslation } from "react-i18next";
import { Text } from "@fluentui/react-components";

export const Error = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: tokens.fontFamilyBase,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: tokens.spacingVerticalM,
          alignItems: "center",
        }}
      >
        <Image src={Turtle} style={{ maxWidth: "80vw" }} />
        {children === undefined && (
          <Text align="center" size={500} weight="semibold">
            {t("errorMessage")}
          </Text>
        )}
        {children}
      </div>
    </div>
  );
};
