import {
  tokens,
  Text,
  Divider,
  makeStyles,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";
import { MatchDetailType } from "@winnerscore/common";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { LAYOUT_SHIFT, usePageStyle } from "../../../pageStyle";
import { TeamHeader } from "./TeamHeader";
import { StatRow } from "./StatRow";

const useContainerStyle = makeStyles({
  container: {
    ...shorthands.gridArea("aside"),
    maxWidth: "776px",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      ...shorthands.gridArea("content-right"),
      maxWidth: "388px",
      overflowY: undefined,
      alignSelf: "start",
    },
    display: "flex",
    width: "100%",
    alignSelf: "center",
  },
  mobileHidden: {
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "inherit",
    },
  },
});

export function MatchStatistics({ match }: { match: MatchDetailType }) {
  const { t } = useTranslation();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { divider } = usePageStyle();
  const { container, mobileHidden } = useContainerStyle();
  const dividerDesktop = mergeClasses(divider, mobileHidden);
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          maxWidth: desktop ? "388px" : "786px",
          gridArea: desktop ? "title-right" : "aside",
        }}
      >
        <Divider vertical className={dividerDesktop} />
        <div style={{ flexGrow: 1, paddingTop: tokens.spacingVerticalM }}>
          <Text
            style={{
              flexGrow: 1,
              display: desktop ? "inherit" : "none",
            }}
            weight="bold"
            size={400}
            align="center"
          >
            {t("matchs.stats")}
          </Text>
        </div>
      </div>

      <div className={container}>
        <Divider vertical className={dividerDesktop} />
        <div
          style={{
            flexGrow: 1,
            paddingLeft: desktop
              ? tokens.spacingHorizontalL
              : tokens.spacingHorizontalNone,
            paddingTop: tokens.spacingVerticalL,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "40px",
              flexGrow: 1,
            }}
          >
            <TeamHeader
              imageUrl={match.team_a.image ?? ""}
              teamName={match.team_a.team_name}
            />
            <TeamHeader
              imageUrl={match.team_b.image ?? ""}
              teamName={match.team_b.team_name}
              side="right"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {match.stats.general.map((s) => (
              <StatRow
                key={s.name}
                label={s.name}
                valueA={s.valueTeamA}
                valueB={s.valueTeamB}
                percent={
                  isNaN(Math.abs(s.valueTeamA / (s.valueTeamA + s.valueTeamB)))
                    ? 0
                    : Math.abs(s.valueTeamA / (s.valueTeamA + s.valueTeamB))
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
