import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import "./index.css";
import "./i18n";
import { DEFAULT_COMPONENT_ICONS } from "@azure/communication-react";
import { initializeIcons, registerIcons } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "./components/Error";
import { tokens } from "@fluentui/react-components";

// If you don't want to provide custom icons, you can register the default ones included with the library.
// This will ensure that all the icons are rendered correctly.
initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={
        <div
          style={{
            width: "100vw",
            height: "100vh",
            fontFamily: tokens.fontFamilyBase,
          }}
        >
          <Error></Error>
        </div>
      }
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
