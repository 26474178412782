import {
  Button,
  Divider,
  Input,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Send24Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { useChatStyle } from "./chatUtils";
import { usePageStyle } from "../../pageStyle";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    ...shorthands.padding(tokens.spacingVerticalXXL),
    maxWidth: "776px",
  },
  input: {
    flexGrow: 1,
  },
});

const MAX_MESSAGE_LENGTH = 255;

export function InputMessage({
  onSend,
}: {
  onSend: (message: string) => void;
}) {
  const { container, input } = useStyle();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const { chatDivider } = useChatStyle();
  const { divider } = usePageStyle();
  const dividerClass = mergeClasses(divider, chatDivider);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "88px",
      }}
    >
      <Divider className={dividerClass} />
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Divider vertical className={dividerClass} />
        <div className={container}>
          <Input
            placeholder={t("chat.message")}
            className={input}
            value={message}
            appearance="outline"
            size="medium"
            onChange={(_, data) => {
              if (data.value.length <= MAX_MESSAGE_LENGTH) {
                setMessage(data.value);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                const removeEnter = message.trimEnd();
                if (removeEnter) {
                  onSend(removeEnter);
                  setMessage("");
                }
              }
            }}
          />
          <Button
            appearance="transparent"
            size="small"
            onClick={() => {
              onSend(message);
              setMessage("");
            }}
          >
            <Send24Regular />
          </Button>
        </div>
      </div>
    </div>
  );
}
