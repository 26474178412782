import {
  makeStyles,
  mergeClasses,
  Text,
  tokens,
} from "@fluentui/react-components";
import { SportSoccer24Regular } from "@fluentui/react-icons";
import { MOBILE_SHIFT } from "../../pageStyle";
import { MatchPage } from "../../../type";
import { useScorer } from "../../scorerHook";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: tokens.spacingVerticalL,
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      gridRowStart: 3,
      paddingTop: tokens.spacingVerticalM,
      backgroundColor: tokens.colorNeutralBackground3,
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: tokens.spacingHorizontalL,
    height: tokens.spacingVerticalL,
    color: "rgba(75, 83, 188, 1)",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  left: {
    gridColumnStart: 1,
    paddingLeft: tokens.spacingHorizontalM,
  },
  right: {
    gridColumnStart: 3,
    paddingRight: tokens.spacingHorizontalM,
  },
  blank: {
    display: "block",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "none",
    },
    gridColumnStart: 2,
    gridRowStart: 3,
    backgroundColor: tokens.colorNeutralBackground3,
  },
});

export function Scorers({
  match,
  side = "left",
}: {
  match: MatchPage;
  side?: "left" | "right";
}) {
  const { container, logo, grow, row, left, right, blank } = useStyle();
  const scorers = (side === "left" ? match.team_a : match.team_b).scorers;
  const { normalGoals } = useScorer(scorers);

  return (
    <>
      {side === "right" && <span className={blank}></span>}
      <div
        className={
          side === "right"
            ? mergeClasses(container, grow, right)
            : mergeClasses(container, grow, left)
        }
      >
        {normalGoals.map((goal) => {
          if (side === "left") {
            return (
              <div key={goal} className={row}>
                <Text className={grow}>{goal}</Text>
                <span className={logo}>
                  <SportSoccer24Regular />
                </span>
              </div>
            );
          } else {
            return (
              <div key={goal} className={row}>
                <span className={logo}>
                  <SportSoccer24Regular />
                </span>
                <Text
                  align="start"
                  className={grow}
                  style={{ paddingLeft: tokens.spacingHorizontalS }}
                >
                  {goal}
                </Text>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
