import {
  makeStyles,
  tokens,
  Text,
  mergeClasses,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getGifts } from "../../api";
import { GIFTS_KEY } from "../../utils";
import { MOBILE_SHIFT, usePageStyle } from "../pageStyle";
import { GiftType } from "@winnerscore/common";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { Ranking } from "../pronostics/Ranking/Ranking";
import { GiftCard } from "./GiftCard";
import { useDesktopHeader } from "../../store";

const useGiftStyle = makeStyles({
  page: {
    display: "grid",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    gridTemplateColumns: "1fr",
    overflowY: "auto",
    height: "calc(100% - 72px)",
  },
});

export function GiftPage() {
  const { page } = useGiftStyle();
  const { main, mainHeader, mainWithoutHeader } = usePageStyle();
  const { t } = useTranslation();
  const desktop = useMediaQuery(`(min-width: ${MOBILE_SHIFT})`);
  const { header } = useDesktopHeader();

  const [gifts, setGifts] = useState<GiftType[]>([]);
  const { data } = useQuery({
    queryKey: [GIFTS_KEY],
    queryFn: getGifts,
  });

  useEffect(() => {
    if (data?.length && data.length > 2) {
      data.sort((a, b) => a.rank - b.rank);
      // Put first on second postion if on desktop
      let sortedData = data;
      if (desktop) {
        sortedData = [data[1], data[0], ...data.slice(2)];
      }
      setGifts(sortedData);
    } else if (data) {
      setGifts(data);
    }
  }, [data, desktop]);

  return (
    <>
      <div
        className={mergeClasses(
          main,
          header && mainHeader,
          header === false && mainWithoutHeader
        )}
      >
        <div
          style={{
            paddingTop: tokens.spacingVerticalXXL,
            paddingBottom: tokens.spacingVerticalM,
          }}
        >
          <Text size={400} weight="bold">
            {t("giftTitle")}
          </Text>
        </div>

        <div className={page}>
          {gifts?.map((gift) => {
            return <GiftCard gift={gift} key={gift.id} />;
          })}
        </div>
      </div>
      {desktop && <Ranking />}
    </>
  );
}
