import { Text } from "@fluentui/react-components";
import { MatchStatus } from "../../../type";
import { getPointsColor } from "./pointsUtils";

export function Points({
  points,
  status,
  pointsMax,
}: {
  points: number;
  status: MatchStatus;
  pointsMax: number;
}) {
  const color = getPointsColor({ points, status, pointsMax });
  return (
    <Text
      align="center"
      weight="regular"
      size={300}
      style={{ alignSelf: "center", color: color }}
    >
      {points}/{pointsMax} Pts
    </Text>
  );
}
