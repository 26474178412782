import {
  makeStyles,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import { MatchPage } from "../../../type";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { PRONOSTICS } from "../../../utils";
import { getProno } from "../../../api";
import { isPronostic } from "@winnerscore/common";
import { useMediaQuery } from "usehooks-ts";
import { useTimeFormatter } from "../MatchPreview/useTimeFormatter";
import { LAYOUT_SHIFT, MOBILE_SHIFT } from "../../pageStyle";
import { useScorer } from "../../scorerHook";

const useStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "120px",
    ...shorthands.margin(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalXS
    ),
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      ...shorthands.margin(
        tokens.spacingVerticalNone,
        tokens.spacingHorizontalXL
      ),
    },
    rowGap: tokens.spacingVerticalS,
  },
  scoreRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    width: "100%",
    justifyItems: "center",
    paddingTop: tokens.spacingVerticalXXL,
  },
  statusText: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundStaticInverted,
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
  },
  pronostics: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) auto minmax(0, 1fr)",
    width: "100%",
    justifyItems: "center",
    alignItems: "center",
    paddingTop: tokens.spacingVerticalM,
  },
});

export function Score({ match }: { match: MatchPage }) {
  const { container, scoreRow, statusText, pronostics } = useStyle();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { startDate } = useTimeFormatter(match.start_date);
  const { penalties: penoA } = useScorer(match.team_a.scorers);
  const { penalties: penoB } = useScorer(match.team_b.scorers);

  const { data } = useQuery({
    queryKey: [PRONOSTICS, match.id],
    queryFn: async () => {
      const data = await getProno(match.id);
      return data;
    },
  });

  const { t } = useTranslation();
  const status = match.active
    ? "active"
    : match.started
      ? "over"
      : "notStarted";

  return (
    <div className={container}>
      {!desktop && (
        <>
          <Text>{startDate}</Text>
          {data && (
            <div className={pronostics}>
              <Text weight="semibold" size={600}>
                {isPronostic(data) && data.team_a}
              </Text>
              <Text weight="bold">-</Text>
              <Text weight="semibold" size={600}>
                {isPronostic(data) && data.team_b}
              </Text>
            </div>
          )}
        </>
      )}
      {match.started && desktop && (
        <div className={scoreRow}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: tokens.spacingHorizontalXS,
            }}
          >
            {penoA !== 0 && <Text size={500}>({penoA})</Text>}
            <Text size={600} weight="medium">
              {match.score[0].normal}
            </Text>
          </span>

          <Text size={600} weight="medium">
            -
          </Text>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: tokens.spacingHorizontalXS,
            }}
          >
            <Text size={600} weight="medium">
              {match.score[1].normal}
            </Text>
            {penoB !== 0 && <Text size={500}>({penoB})</Text>}
          </span>
        </div>
      )}
      <Text className={statusText}>{t(`matchs.status.${status}`)}</Text>
      {desktop && (
        <>
          <Text weight="semibold">{t("yourProno")}</Text>
          <div>
            {data && isPronostic(data) && (
              <>
                <Text weight="bold">{data.team_a}</Text>
                <Text
                  weight="bold"
                  style={{
                    paddingLeft: tokens.spacingHorizontalMNudge,
                    paddingRight: tokens.spacingHorizontalMNudge,
                  }}
                >
                  -
                </Text>
                <Text weight="bold">{data.team_b}</Text>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
