import { Fragment } from "react";
import { Match } from "../../../type";
import { Matchs } from "./Matchs";

type Phase = {
  phase: string;
  matchs: Match[];
};

export function MatchList({ matchs }: { matchs: Match[] }) {
  const phases = matchs.reduce((ph: Phase[], match) => {
    const phase = ph.find((p) => p.phase === match.phase);
    if (phase) {
      phase.matchs.push(match);
    } else {
      ph.push({ phase: match.phase, matchs: [match] });
    }
    return ph;
  }, []);

  return (
    <>
      {phases.map((phase) => {
        return (
          <Fragment key={phase.phase}>
            <Matchs matchs={phase.matchs} phase={phase.phase} />
          </Fragment>
        );
      })}
    </>
  );
}
