import { Type, Static, TSchema } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { MAX_POINTS, DATA_NOT_FOUND } from "./constant";

export { MAX_POINTS, DATA_NOT_FOUND };

const Nullable = <T extends TSchema>(schema: T) =>
  Type.Union([Type.Null(), schema]);

export const MatchPath = Type.Object({
  matchId: Type.Integer({ minimum: 1 }),
});

export type MatchPathType = Static<typeof MatchPath>;

export const WebSocketComment = Type.Object({
  userId: Type.String(),
  message: Type.String(),
  userName: Type.String(),
});

export enum MessageType {
  List,
  Message,
}

export const WebSocketCommentWithDate = Type.Object({
  userId: Type.String(),
  userName: Type.String(),
  message: Type.String(),
  createdOn: Type.String(),
  id: Type.Optional(Type.Integer({ minimum: 1 })),
});

export type WebSocketCommentWithDateType = Static<
  typeof WebSocketCommentWithDate
>;

const CommentList = Type.Object({
  messages: Type.Array(WebSocketCommentWithDate),
  type: Type.Literal(MessageType.List),
});

export const WebSocketServer = Type.Union([
  Type.Composite([
    WebSocketCommentWithDate,
    Type.Object({ type: Type.Literal(MessageType.Message) }),
  ]),
  CommentList,
]);

export type WebSocketServerType = Static<typeof WebSocketServer>;

export type WebSocketCommentType = Static<typeof WebSocketComment>;
export const WebSocketCheck = TypeCompiler.Compile(WebSocketComment);

const TeamGeneral = Type.Object({
  id: Type.Integer({ minimum: 1 }),
  team_name: Type.String(),
  image: Nullable(Type.String()),
  known: Type.Boolean(),
});

const Scorer = Type.Object({
  name: Type.String(),
  elapsed: Type.Integer({ minimum: 0 }),
  penalty: Type.Boolean(),
  own_goal: Type.Boolean(),
});

const TeamDetail = Type.Composite([
  TeamGeneral,
  Type.Object({ scorers: Type.Array(Scorer) }),
]);

const Stats = Type.Object({
  name: Type.String(),
  valueTeamA: Type.Integer({ minimum: 0 }),
  valueTeamB: Type.Integer({ minimum: 0 }),
  general: Type.Boolean(),
});

export type StatType = Static<typeof Stats>;

const MatchValidation = Type.Object({
  start_date: Type.String(),
  team_a: Type.Integer({ minimum: 1 }),
  team_b: Type.Integer({ minimum: 1 }),
  referee: Nullable(Type.String()),
  round: Nullable(Type.String()),
  winner: Nullable(Type.Integer()),
});

export const MatchList = Type.Array(MatchValidation);

const Score = Type.Object({
  normal: Type.Integer({ minimum: 0 }),
  penalties: Nullable(Type.Integer()),
});

const MatchGeneral = Type.Object({
  id: Type.Integer({ minimum: 1 }),
  stadium: Type.String(),
  referee: Nullable(Type.String()),
  start_date: Type.String(),
  phase: Type.String(),
  group: Nullable(Type.String()),
  active: Type.Boolean(),
  score: Type.Array(Score, { minItems: 2, maxItems: 2 }),
  pronostics: Nullable(
    Type.Array(Type.Integer({ minimum: 0 }), { minItems: 2, maxItems: 2 })
  ),
  points: Nullable(Type.Integer({ minimum: 0 })),
  pointsMax: Type.Integer(),
});

export const MatchResponse = Type.Composite([
  MatchGeneral,
  Type.Object({ team_a: TeamGeneral, team_b: TeamGeneral }),
]);

export const MatchResponseArray = Type.Array(MatchResponse);
export type MatchResponseType = Static<typeof MatchResponseArray>;

export const CompanyGraphResponse = Type.Object({
  value: Type.Array(
    Type.Object({
      id: Type.String(),
      displayName: Type.String(),
      postalCode: Type.String(),
      businessPhones: Type.Array(Type.String()),
      city: Nullable(Type.String()),
    })
  ),
});

export type CompanyResponseType = Static<typeof CompanyGraphResponse>;

export const WebSocketAuth = Type.Object({
  token: Type.String(),
});

export const WebSocketAtuhCheck = TypeCompiler.Compile(WebSocketAuth);

export type ChangeStatus = "constant" | "up" | "down";

export const User = Type.Object({
  id: Type.String(),
  rank: Type.Integer(),
  total: Type.Integer(),
  displayname: Type.String(),
  change: Type.Union([
    Type.Literal("constant"),
    Type.Literal("down"),
    Type.Literal("up"),
  ]),
});

export type UserType = Static<typeof User>;

export const RankingResponse = Type.Object({
  users: Type.Array(User),
  nextPage: Type.Optional(Type.Integer()),
});
export type RankingResponseType = Static<typeof RankingResponse>;

const RankDetail = Type.Composite([
  User,
  Type.Object({
    perfect: Type.Integer({ minimum: 0 }),
    good: Type.Integer({ minimum: 0 }),
    lost: Type.Integer({ minimum: 0 }),
  }),
]);

export const RankingDetailReponse = Type.Object({
  users: Type.Array(RankDetail),
  nextPage: Type.Optional(Type.Integer()),
});

export type RankingDetailReponseType = Static<typeof RankingDetailReponse>;

export const ErrorType = Type.Object({
  code: Type.Integer(),
  message: Type.String(),
});

export const SetPronoBody = Type.Object({
  matchId: Type.Number({ minimum: 1 }),
  prono: Type.Array(Type.Integer({ minimum: 0, maximum: MAX_POINTS }), {
    minItems: 2,
    maxItems: 2,
  }),
});

const pronoResponse = Type.Object({
  id: Type.Integer({ minimum: 1 }),
  team_a: Type.Integer({ minimum: 0 }),
  team_b: Type.Integer({ minimum: 0 }),
  matchId: Type.Integer({ minimum: 1 }),
});

export const PronoResponse = Type.Union([
  pronoResponse,
  Type.Object({ message: Type.Literal(DATA_NOT_FOUND) }),
]);

export type PronoResponseType = Static<typeof pronoResponse>;

export type PronoResponseTypeMixed = Static<typeof PronoResponse> | undefined;

export const isPronostic = (
  prono: PronoResponseTypeMixed
): prono is PronoResponseType => {
  if (prono && "message" in prono) {
    return false;
  }
  return true;
};

export const LangQuery = Type.Object({
  lang: Type.String(),
});

export const CountryQuery = Type.Object({
  lang: Type.String(),
  competitionId: Type.Integer(),
});

export const IdParam = Type.Object({
  id: Type.Integer({ minimum: 1 }),
});

export const StringIdParam = Type.Object({
  id: Type.String(),
});

export type MatchDetailType = Static<typeof MatchDetail>;

export const MatchDetail = Type.Composite([
  MatchGeneral,
  Type.Object({
    team_a: TeamDetail,
    team_b: TeamDetail,
    stats: Type.Object({
      general: Type.Array(Stats),
      details: Type.Array(Stats),
    }),
  }),
]);

export const Company = Type.Object({
  id: Type.String(),
  displayName: Type.String(),
  phones: Type.Array(Type.String()),
  city: Type.String(),
  postalCode: Type.String(),
});

export type CompanyType = Static<typeof Company>;

export const CreateUser = Type.Object({
  id: Type.String(),
  displayName: Type.String(),
  company: Company,
});

export type CreateUserType = Static<typeof CreateUser>;

export const Gift = Type.Object({
  id: Type.Integer({ minimum: 1 }),
  title: Type.String(),
  description: Nullable(Type.String()),
  image_file: Type.String(),
  rank: Type.Integer({ minimum: 1 }),
  company_id: Type.String(),
});

export type GiftType = Static<typeof Gift>;

export const Country = Type.Object({
  name: Type.String(),
  flag: Type.String(),
  id: Type.Integer(),
});

export type CountryType = Static<typeof Country>;

export const Pronostic = Type.Object({
  gold: Nullable(Country),
  silver: Nullable(Country),
  bronze: Nullable(Country),
});

export type PronosticType = Static<typeof Pronostic>;

export const Competition = Type.Object({
  id: Type.Integer(),
  question: Type.String(),
  sport: Type.String(),
  date: Type.String(),
  gold: Nullable(Country),
  silver: Nullable(Country),
  bronze: Nullable(Country),
  pronostic: Nullable(Pronostic),
  points: Type.Number(),
});

export type CompetitionType = Static<typeof Competition>;

export const PronosticBody = Type.Object({
  gold: Nullable(Type.Number()),
  silver: Nullable(Type.Number()),
  bronze: Nullable(Type.Number()),
  competitionId: Type.Number(),
});
