import { CompetitionType } from "@winnerscore/common";
import {
  tokens,
  Text,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { Fragment } from "react/jsx-runtime";
import { Competition } from "./Competition";
import { LAYOUT_SHIFT } from "../../pageStyle";

type Sport = {
  name: string;
  competitions: CompetitionType[];
};

const useCompetitionStyle = makeStyles({
  title: {
    alignItems: "center",
    gridColumnStart: 2,
    backgroundColor: tokens.colorNeutralBackground5,
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: tokens.spacingVerticalL,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "grid",
      gridTemplateColumns: "repeat(3, minmax(0px, 1fr)) 0.5fr",
      paddingTop: tokens.spacingVerticalL,
      justifyItems: "center",
    },
  },
});

export const Competitions = ({
  competitions,
}: {
  competitions: CompetitionType[];
}) => {
  const { title, titleContainer } = useCompetitionStyle();
  const grouped = competitions.reduce((acc: Sport[], comp) => {
    const sport = acc.find((s) => s.name === comp.sport);
    if (sport) {
      sport.competitions.push(comp);
    } else {
      acc.push({ name: comp.sport, competitions: [comp] });
    }
    return acc;
  }, []);

  return (
    <div>
      {grouped.map((sport) => (
        <Fragment key={sport.name}>
          <div className={titleContainer}>
            <Text weight="semibold" className={title}>
              {sport.name}
            </Text>
          </div>
          {sport.competitions.map((comp) => (
            <Competition competition={comp} key={comp.id} />
          ))}
        </Fragment>
      ))}
    </div>
  );
};
