import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { getMatchDetail } from "../../../api";
import { getMatchStatus, MATCH_KEY, useChatSocket } from "../../../utils";
import { LAYOUT_SHIFT, MOBILE_SHIFT, usePageStyle } from "../../pageStyle";
import { Chat } from "../Chat/Chat";
import { DetailTab } from "./DetailTab";
import { DetailGeneral } from "./DetailGeneral";
import { DetailsHeader } from "./DetailsHeader";
import { DetailsContent } from "./DetailsContent";
import { InputMessage } from "../Chat/InputMessage";
import { useCallback, useState } from "react";
import { MessageType, WebSocketServerType } from "@winnerscore/common";
import { toDomainMessage } from "../Chat/chatUtils";
import { useCurrentUser, useDesktopHeader, useTabDetail } from "../../../store";
import { ChatMessage } from "@azure/communication-react";
import { TabValue } from "../../../type";

const useDetailsStyle = makeStyles({
  details: {
    width: "min(776px, 100%)",
    alignSelf: "center",
    gridColumnStart: 1,
    gridColumnEnd: 3,
    display: "flex",
    flexDirection: "column",
    paddingTop: tokens.spacingVerticalXXL,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      maxHeight: "calc(100vh - 274px)",
    },
  },
  chatWithoutHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      height: "calc(100vh - 52px)",
    },
  },
  chatHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      height: "calc(100vh - 208px)",
    },
  },
  detailsWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "stretch",
    paddingRight: tokens.spacingHorizontalXS,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      paddingRight: tokens.spacingHorizontalL,
    },
    width: "min(100%, 776px)",
  },
});

export function MatchDetails() {
  const { matchId } = useParams();
  const id = Number(matchId);
  const { main, aside, mainHeader, mainWithoutHeader } = usePageStyle();
  const { details, chatHeader, chatWithoutHeader, detailsWrapper } =
    useDetailsStyle();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const user = useCurrentUser();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const currentTab = useTabDetail((s) => s.value);
  const chat = currentTab === TabValue.Chat || desktop;
  const { header } = useDesktopHeader();

  const { data: match } = useQuery({
    queryKey: [MATCH_KEY, id],
    queryFn: async () => {
      const data = await getMatchDetail(id);
      return data;
    },
  });
  const status = getMatchStatus(match);

  const callback = useCallback(
    (message: WebSocketServerType) => {
      if (message.type === MessageType.List) {
        const domainMessages = message.messages.map((m) =>
          toDomainMessage(m, user.id)
        );
        setMessages(domainMessages);
      } else if (message.type === MessageType.Message) {
        const domainMessage = toDomainMessage(message, user.id);
        setMessages((m) => {
          return [...m, domainMessage];
        });
      }
    },
    [user.id]
  );

  const { send } = useChatSocket({
    matchId: matchId ? id : undefined,
    onMessage: callback,
  });

  return (
    <>
      <div
        className={mergeClasses(
          main,
          header && mainHeader,
          header === false && mainWithoutHeader
        )}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: tokens.spacingVerticalXXXL,
            width: "100%",
            position: "relative",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <div className={detailsWrapper}>
            <DetailsHeader
              title={match?.phase}
              points={match?.points ?? 0}
              status={status}
              pointsMax={match?.pointsMax ?? 0}
            />
            {match && (
              <div className={details}>
                <DetailGeneral match={match} />
              </div>
            )}
            <DetailTab />
            {match && <DetailsContent match={match} wrap={desktop} />}
            {!desktop && currentTab === TabValue.Chat && (
              <>
                <div style={{ flexGrow: 1 }}>
                  <Chat messages={messages} />
                </div>
                <InputMessage
                  onSend={(message) => {
                    const dataMessage = {
                      userId: user.id,
                      message,
                      userName: user.username,
                    };
                    send(dataMessage);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {chat && desktop && (
        <div
          className={mergeClasses(
            aside,
            header ? chatHeader : chatWithoutHeader
          )}
          style={{ gridTemplateRows: desktop ? "1fr 88px" : "88px" }}
        >
          <Chat messages={messages} />
          <InputMessage
            onSend={(message) => {
              const dataMessage = {
                userId: user.id,
                message,
                userName: user.username,
              };
              send(dataMessage);
            }}
          />
        </div>
      )}
    </>
  );
}
