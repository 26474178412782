import {
  makeStyles,
  tokens,
  shorthands,
  Text,
} from "@fluentui/react-components";
import { Match } from "../../../type";
import { MOBILE_SHIFT } from "../../pageStyle";
import { MatchRow } from "./MatchRow";

const useMatchStyle = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
    alignItems: "center",
  },
  title: {
    backgroundColor: tokens.colorNeutralBackground5,
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridColumnStart: 3,
    },
    gridColumnStart: 2,
  },
  titleContainer: {
    alignSelf: "stretch",
    display: "grid",
    marginTop: tokens.spacingVerticalM,
    marginRight: tokens.spacingHorizontalL,
    gridTemplateColumns: "repeat(3, 1fr)",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateColumns:
        "repeat(4, minmax(0, 1fr)) minmax(0, 0.5fr) minmax(0, 0.5fr)",
      marginRight: tokens.spacingHorizontalNone,
    },
  },
  titleDiv: {
    display: "flex",
    justifyContent: "center",
    gridColumnStart: 2,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridColumnStart: 3,
    },
  },
});

export function Matchs({ phase, matchs }: { phase: string; matchs: Match[] }) {
  const { container, title, titleContainer, titleDiv } = useMatchStyle();

  return (
    <div className={container}>
      <div className={titleContainer}>
        <div className={titleDiv}>
          <Text className={title} align="center" weight="semibold">
            {phase}
          </Text>
        </div>
      </div>

      {matchs.map((match, i) => {
        return <MatchRow match={match} key={i} />;
      })}
    </div>
  );
}
