import { Person } from "@microsoft/mgt-react";
import { Text, tokens } from "@fluentui/react-components";

export function RankingRow({ id, name }: { id: string; name: string }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        columnGap: tokens.spacingHorizontalS,
      }}
    >
      {id && (
        <Person
          showPresence
          className="person"
          fetchImage
          userId={id}
          key={id}
        />
      )}
      <Text>{name}</Text>
    </div>
  );
}
