import {
  Subtitle2Stronger,
  tokens,
  Divider,
  mergeClasses,
  makeStyles,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useCurrentUser, useDesktopHeader } from "../../../store";
import { ChatMessage, MessageThread } from "@azure/communication-react";
import { useChatStyle } from "./chatUtils";
import { Person } from "@microsoft/mgt-react";
import { FluentThemeProvider } from "@azure/communication-react";
import { LAYOUT_SHIFT, usePageStyle } from "../../pageStyle";
import { useMediaQuery } from "usehooks-ts";

const useStyle = makeStyles({
  containerWithHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      maxHeight: "calc(100vh - 208px - 88px)",
    },
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  containerWithoutHeader: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      maxHeight: "calc(100vh - 52px - 88px)",
    },
    flexGrow: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

export function Chat({ messages }: { messages: ChatMessage[] }) {
  const { divider } = usePageStyle();
  const { chat, chatDivider } = useChatStyle();
  const { t } = useTranslation();
  const { id } = useCurrentUser();
  const { containerWithHeader, containerWithoutHeader } = useStyle();
  const sendBox = useRef<HTMLTextAreaElement>();
  const [backgroundColor, setBackgroundColor] = useState("");
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { header } = useDesktopHeader();

  const dividerClass = mergeClasses(divider, chatDivider);

  useEffect(() => {
    const element = document.getElementById("sendbox") as HTMLTextAreaElement;
    sendBox.current = element;
    const provider = document.getElementsByClassName("fui-FluentProvider")[0];
    const bg = getComputedStyle(provider).getPropertyValue(
      "--colorNeutralBackground3"
    );
    setBackgroundColor(bg);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        maxWidth: "776px",
      }}
    >
      <Divider vertical className={dividerClass} />
      <div className={header ? containerWithHeader : containerWithoutHeader}>
        <div className={chat}>
          <Subtitle2Stronger>{t("chat.chat")}</Subtitle2Stronger>
        </div>
        {id && (
          <FluentThemeProvider
            fluentTheme={{
              palette: {
                white: backgroundColor,
              },
            }}
          >
            <MessageThread
              userId={id}
              disableEditing={true}
              showMessageDate={true}
              messages={messages}
              onRenderAvatar={(userId) => {
                return (
                  <Person className="person" userId={userId} showPresence />
                );
              }}
              styles={{
                root: {
                  flexGrow: 1,
                },
                chatMessageContainer: {
                  backgroundColor: "white",
                },
                chatContainer: {
                  paddingLeft: desktop
                    ? tokens.spacingHorizontalXXL
                    : tokens.spacingHorizontalNone,
                  paddingTop: desktop
                    ? tokens.spacingVerticalNone
                    : tokens.spacingHorizontalNone,
                  paddingRight: tokens.spacingHorizontalNone,
                  paddingBottom: tokens.spacingVerticalNone,
                },
              }}
            />
          </FluentThemeProvider>
        )}
      </div>
    </div>
  );
}
