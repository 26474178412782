import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useMediaQuery } from "usehooks-ts";
import { MatchPage } from "../../../type";
import { MOBILE_SHIFT } from "../../pageStyle";
import { FlagWithBackground } from "./FlagWithBackground";
import { Score } from "./Score";

const useStyle = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "minmax(0, 1fr) auto minmax(0, 1fr)",
    columnGap: tokens.spacingHorizontalXXS,
    ...shorthands.padding(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalXS
    ),
    gridColumnStart: 1,
    gridColumnEnd: 4,
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      columnGap: tokens.spacingHorizontalL,
      ...shorthands.padding(
        tokens.spacingVerticalNone,
        tokens.spacingHorizontalXXXL
      ),
      gridColumnStart: 2,
      gridColumnEnd: 3,
    },
    alignItems: "start",
    paddingLeft: tokens.spacingHorizontalS,
    paddingRight: tokens.spacingHorizontalS,
  },
});

export function Resume({ match }: { match: MatchPage }) {
  const { container } = useStyle();
  const desktop = useMediaQuery(`(min-width: ${MOBILE_SHIFT})`);
  const size = desktop ? 48 : 42;
  return (
    <div className={container}>
      <FlagWithBackground
        url={match.team_a.image ?? ""}
        name={match.team_a.team_name}
        size={size}
      />
      <Score match={match} />
      <FlagWithBackground
        url={match.team_b.image ?? ""}
        name={match.team_b.team_name}
        size={size}
      />
    </div>
  );
}
