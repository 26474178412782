import {
  Subtitle2Stronger,
  TableColumnDefinition,
  createTableColumn,
  tokens,
  Text,
  Divider,
  TableColumnId,
  DataGridHeader,
  DataGridCell,
  DataGridBody,
  DataGridHeaderCell,
  DataGridProps,
  Spinner,
} from "@fluentui/react-components";
import { ContactCard24Regular, PeopleTeamRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Rank } from "./Rank";
import { Personal } from "./Personal";
import { useRightPart } from "../pronoStyle";
import { useQuery } from "@tanstack/react-query";
import { TIPSTERS_KEY, USERS_KEY } from "../../../utils";
import { RankingRow } from "./RankingRow";
import { getRankingPagination, getTipsters } from "../../../api";
import { RankingResponseType } from "@winnerscore/common";
import { usePageStyle } from "../../pageStyle";
import {
  DataGrid,
  DataGridRow,
} from "@fluentui-contrib/react-data-grid-react-window";
import { PeoplePicker } from "@microsoft/mgt-react";

const WIDTHS: Record<TableColumnId, string> = {
  name: "auto",
  rank: "65px",
  points: "65px",
};

const HEADER_WIDTHS: Record<TableColumnId, string> = {
  ...WIDTHS,
  points: "calc(65px + 0.5rem)",
};

type Item = RankingResponseType["users"][0];

// const ItemByPage = 100;

export function Ranking() {
  const { searchWrapper } = useRightPart();
  const { t } = useTranslation();
  const [userList, setUserList] = useState<Item[]>([]);
  const [page, _] = useState(0);
  const { aside, divider } = usePageStyle();
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<Item[]>([]);
  const [sortState, setSortState] = useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "rank",
    sortDirection: "ascending",
  });

  const columns: TableColumnDefinition<Item>[] = [
    createTableColumn<Item>({
      columnId: "rank",
      renderHeaderCell: () => <Text>{t("rank")}</Text>,
      renderCell: (item) => {
        return <Rank rank={item.rank} change={item.change} />;
      },
      compare: (a, b) => a.rank - b.rank,
    }),
    createTableColumn<Item>({
      columnId: "name",
      renderHeaderCell: () => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ContactCard24Regular />
            <Text>{t("rankingName")}</Text>
          </div>
        );
      },
      renderCell: (item) => {
        return <RankingRow id={item.id} name={item.displayname} />;
      },
      compare: (a, b) => a.displayname.localeCompare(b.displayname),
    }),
    createTableColumn<Item>({
      columnId: "points",
      renderHeaderCell: () => (
        <Text align="center" style={{ width: "100%" }}>
          {t("matchs.points")}
        </Text>
      ),
      renderCell: (item) => (
        <Text align="center" style={{ width: "100%" }}>
          {item.total}
        </Text>
      ),
      compare: (a, b) => a.rank - b.rank,
    }),
  ];

  const { data, isLoading: rankingLoading } = useQuery({
    queryKey: [USERS_KEY, page],
    queryFn: async () => {
      const data = await getRankingPagination(page);
      return data;
    },
  });
  const { data: tipsters } = useQuery({
    queryKey: [TIPSTERS_KEY],
    queryFn: getTipsters,
  });

  useEffect(() => {
    if (data?.users) {
      setUserList([...data.users]);
    }
  }, [data]);

  useEffect(() => {
    if (search) {
      setFilteredUsers(
        userList.filter((u) => {
          const name = u.displayname.toLowerCase();
          return name.includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredUsers(userList);
    }
  }, [userList, search]);

  return (
    <div className={aside}>
      <div style={{ display: "flex" }}>
        <Divider vertical className={divider} />
        <div style={{ flexGrow: 1, paddingTop: tokens.spacingVerticalXL }}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              columnGap: tokens.spacingHorizontalMNudge,
            }}
          >
            <Subtitle2Stronger
              align="start"
              style={{ paddingLeft: tokens.spacingHorizontalXL }}
            >
              {t("matchs.ranking")}
            </Subtitle2Stronger>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: tokens.colorNeutralForegroundDisabled,
                columnGap: tokens.spacingHorizontalXS,
              }}
              title={t("tipsters")}
            >
              <Text size={200}>{tipsters}</Text>
              <PeopleTeamRegular width={20} />
            </span>
          </div>

          <div className={searchWrapper}>
            <PeoplePicker
              selectionChanged={({ detail }) => {
                if (detail.length) {
                  setSearch(detail[0].displayName ?? "");
                } else {
                  setSearch("");
                }
              }}
              selectionMode="single"
              placeholder={t("search")}
            />
          </div>
          <div
            style={{
              overflow: "hidden",
              paddingLeft: tokens.spacingHorizontalXL,
            }}
          >
            <DataGrid
              columns={columns}
              items={filteredUsers}
              style={{ position: "relative", height: "100%" }}
              sortable
              sortState={sortState}
              onSortChange={(e, s) => {
                setSortState(s);
              }}
            >
              <DataGridHeader
                style={{
                  position: "sticky",
                  top: "0px",
                  backgroundColor: tokens.colorNeutralBackground3,
                }}
              >
                <DataGridRow style={{ paddingTop: tokens.spacingVerticalM }}>
                  {({ renderHeaderCell, columnId }) => (
                    <DataGridHeaderCell
                      style={{
                        flexShrink: 1,
                        flexGrow: columnId === "name" ? 1 : 0,
                        flexBasis: "auto",
                        width: HEADER_WIDTHS[columnId],
                      }}
                    >
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  )}
                </DataGridRow>
              </DataGridHeader>
              {rankingLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: tokens.spacingVerticalXXL,
                  }}
                >
                  <Spinner size="huge" />
                </div>
              ) : filteredUsers.length || !search ? (
                <DataGridBody<Item>
                  style={{
                    overflowY: "scroll",
                    height: `calc(100% - 44px - 45px - 22px)`,
                  }}
                >
                  {({ item, rowId }) => (
                    <DataGridRow<Item> key={rowId}>
                      {({ renderCell, columnId }) => (
                        <DataGridCell
                          style={{
                            flexShrink: 1,
                            flexGrow: columnId === "name" ? 1 : 0,
                            flexBasis: "auto",
                            width: WIDTHS[columnId],
                          }}
                        >
                          {renderCell(item)}
                        </DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingTop: tokens.spacingVerticalXXL,
                  }}
                >
                  <Subtitle2Stronger align="center">
                    {t("noResults.title")}
                  </Subtitle2Stronger>
                  <Text align="center">{t("noResults.details")}</Text>
                </div>
              )}
            </DataGrid>
          </div>
        </div>
      </div>
      <Personal />
    </div>
  );
}
