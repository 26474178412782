import {
  Text,
  tokens,
  makeStyles,
  mergeClasses,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { BackupImage } from "../../common/BackupImage";

const useMatchStyle = makeStyles({
  imgContainer: {
    height: "40px",
    minWidth: "40px",
  },
  country: {
    display: "flex",
    alignItems: "center",
    columnGap: tokens.spacingHorizontalS,
  },
  right: {
    flexDirection: "row-reverse",
    justifyContent: "start",
    alignSelf: "center",
    paddingRight: tokens.spacingHorizontalM,
    "@media (max-width: 849px)": {
      gridColumnStart: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  left: {
    justifyContent: "end",
    alignSelf: "center",
    paddingLeft: tokens.spacingHorizontalM,
    "@media (max-width: 849px)": {
      gridColumnStart: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
});

export function PronosticTeam({
  side = "left",
  teamName,
  url,
  matchId,
  teamKnown,
}: {
  side?: "left" | "right";
  teamName: string;
  url: string;
  matchId: number;
  teamKnown: boolean;
}) {
  const mobile = useMediaQuery("(max-width: 849px)");
  const navigate = useNavigate();

  const { imgContainer, country, right, left } = useMatchStyle();

  if (mobile) {
    return (
      <div className={side === "left" ? left : right}>
        <span
          className={imgContainer}
          onClick={() => {
            if (teamKnown) {
              navigate(`/pronostics/${matchId}`);
            }
          }}
        >
          <BackupImage
            alt={`flag ${teamName}`}
            size={40}
            url={url}
            fit="cover"
            shape="circular"
          />
        </span>
        <Text size={400} align="center">
          {teamName}
        </Text>
      </div>
    );
  } else {
    return (
      <span
        className={
          side === "right"
            ? mergeClasses(country, right)
            : mergeClasses(country, left)
        }
      >
        <Text weight="regular" size={500}>
          {teamName}
        </Text>
        <span className={imgContainer}>
          <BackupImage
            alt={`flag ${teamName}`}
            url={url}
            size={40}
            shape="circular"
            fit="cover"
          />
        </span>
      </span>
    );
  }
}
