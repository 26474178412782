import {
  Button,
  Divider,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MOBILE_SHIFT } from "../pageStyle";

export const useFooterStyle = makeStyles({
  divider: {
    display: "none",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      display: "flex",
    },
    width: "100%",
  },
  buttons: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "stretch",
    rowGap: tokens.spacingHorizontalM,
    ...shorthands.padding(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalL
    ),
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      alignItems: "center",
      height: "calc(100% - 2px)",
      justifyContent: "end",
      flexDirection: "row-reverse",
      paddingRight: tokens.spacingHorizontalXXXL,
      columnGap: tokens.spacingHorizontalXL,
    },
  },
  button: {
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      width: "100%",
    },
  },
});

export const Footer = ({
  back,
  next,
  nextText,
  onClickNext,
}: {
  back: string;
  next: string;
  nextText?: string;
  onClickNext?: () => void;
}) => {
  const { t } = useTranslation();
  const { divider, buttons, button } = useFooterStyle();
  return (
    <div style={{ gridArea: "footer", alignItems: "center" }}>
      <Divider className={divider} />
      <div className={buttons}>
        <Link to={next}>
          <Button appearance="primary" className={button} onClick={onClickNext}>
            {t(nextText ? nextText : "next")}
          </Button>
        </Link>
        <Link to={back}>
          <Button className={button}>{t("previous")}</Button>
        </Link>
      </div>
    </div>
  );
};
