import { ChatMessage } from "@azure/communication-react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { WebSocketCommentWithDateType } from "@winnerscore/common";
import { nanoid } from "nanoid";
import { LAYOUT_SHIFT } from "../../pageStyle";

export const toDomainMessage = (
  message: WebSocketCommentWithDateType,
  myId: string
): ChatMessage => {
  return {
    messageType: "chat",
    content: message.message,
    senderId: message.userId,
    messageId: message.id?.toString() ?? nanoid(),
    createdOn: new Date(message.createdOn),
    contentType: "text",
    mine: myId === message.userId,
    senderDisplayName: message.userName,
    attached: false,
  };
};

export const useChatStyle = makeStyles({
  chat: {
    ...shorthands.padding(
      tokens.spacingVerticalXL,
      tokens.spacingHorizontalXXL,
      tokens.spacingVerticalNone
    ),
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "inherit",
    },
  },
  chatDivider: {
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "inherit",
    },
  },
});
