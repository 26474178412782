import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import frTranslation from "./locales/fr.json";
import { LocalizationHelper } from "@microsoft/mgt-element";

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV !== "production",
    fallbackLng: "en",
    returnNull: false,
    resources: {
      en: enTranslation,
      fr: frTranslation,
    },
  });

//@ts-ignore
LocalizationHelper.strings = {
  _components: {
    "people-picker": {
      maxSelectionsPlaceHolder: "",
    },
  },
};
