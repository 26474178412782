import {
  Combobox,
  makeStyles,
  shorthands,
  useId,
  useComboboxFilter,
  tokens,
  Text,
} from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackupImage } from "../common/BackupImage";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  listbox: {
    zIndex: 10,
    maxHeight: "250px",
  },
});

export const CountrySelect = ({
  onSelect,
  selected,
  countries,
}: {
  selected?: { id: number; name: string; flag: string };
  onSelect: (selection?: { id: number; name: string; flag: string }) => void;
  countries: { id: number; flag: string; name: string }[];
}) => {
  const comboId = useId("combo-default");
  const { t } = useTranslation();
  const styles = useStyles();
  const [query, setQuery] = useState(selected?.name ?? "");
  const ref = useRef<HTMLDivElement>(null);
  const [countriesRecord, setCountriesRecord] = useState<
    Record<string, { id: number; name: string; flag: string }>
  >({});

  useEffect(() => {
    const record: Record<string, { id: number; name: string; flag: string }> =
      {};
    countries.forEach((country) => {
      record[country.id] = country;
    });
    setCountriesRecord(record);
  }, [countries]);

  const children = useComboboxFilter(
    query,
    countries.map((c) => ({
      children: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            columnGap: tokens.spacingHorizontalS,
          }}
        >
          <BackupImage
            url={c.flag}
            shape="circular"
            fit="default"
            alt={`Flag ${c.name}`}
            size={20}
          />
          <Text>{c.name}</Text>
        </div>
      ),
      value: `${c.id}`,
      text: c.name,
    })),
    {
      noOptionsMessage: t("olympics.noCountry"),
      optionToText: (opt) => opt.text,
      optionToReactKey: (opt) => opt.text,
    }
  );

  const [node, setNode] = useState<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) {
      setNode(ref.current);
    }
  }, [setNode]);

  return (
    <div className={styles.root} ref={ref}>
      <Combobox
        aria-labelledby={comboId}
        clearable
        placeholder={t("olympics.countrySearch")}
        onChange={(e) => setQuery(e.target.value)}
        onOptionSelect={(e, data) => {
          if (data.optionText) {
            setQuery(data.optionText);
          } else {
            setQuery("");
          }
          const selectedOption = countriesRecord[data.optionValue ?? ""];
          onSelect(selectedOption);
        }}
        value={query}
        mountNode={node}
        style={{ minWidth: "unset" }}
        input={{ width: "220px" }}
        listbox={{ className: styles.listbox }}
      >
        {children}
      </Combobox>
    </div>
  );
};
