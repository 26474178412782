import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { useDesktopHeader } from "../../../store";
import { LAYOUT_SHIFT, MOBILE_SHIFT, usePageStyle } from "../../pageStyle";
import { PronosticsHeader } from "./PronosticsHeader";
import { PronosticsList } from "./PronosticsList";

const usePronosticStyle = makeStyles({
  pronosticLayout: {
    gridTemplateRows: "28px 1fr",
    [`@media (min-width: ${MOBILE_SHIFT})`]: {
      gridTemplateRows: "65px 1fr",
    },
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      paddingTop: tokens.spacingVerticalXL,
    },
    display: "grid",
    height: "100%",
    width: "100%",
  },
});

export function Pronostics() {
  const { main, mainHeader, mainWithoutHeader } = usePageStyle();
  const { pronosticLayout } = usePronosticStyle();
  const { header } = useDesktopHeader();

  return (
    <div
      className={mergeClasses(
        main,
        header && mainHeader,
        header === false && mainWithoutHeader
      )}
    >
      <div className={pronosticLayout}>
        <PronosticsHeader />
        <PronosticsList />
      </div>
    </div>
  );
}
