import { CheckmarkStarburst16Regular } from "@fluentui/react-icons";
import {
  makeStyles,
  shorthands,
  tokens,
  Text,
} from "@fluentui/react-components";

const useExampleStyle = makeStyles({
  li: {
    display: "flex",
    alignItems: "center",
    ...shorthands.padding(
      tokens.spacingVerticalXS,
      tokens.spacingHorizontalNone
    ),
  },
  icon: {
    color: tokens.colorBrandForegroundOnLight,
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
  },
});

export function Example({ text }: { text: string }) {
  const { li, icon } = useExampleStyle();
  return (
    <li className={li}>
      <span className={icon}>
        <CheckmarkStarburst16Regular />
      </span>
      <Text>{text}</Text>
    </li>
  );
}
