import { Select } from "@fluentui/react-components";
import { useLanguage } from "../../store";

export const LangSelect = () => {
  const { lang, set } = useLanguage();
  return (
    <Select
      appearance="underline"
      style={{ maxWidth: "100px" }}
      value={lang}
      onChange={(_, data) => {
        set(data.value);
      }}
    >
      <option value="fr">Français</option>
      <option value="en">English</option>
    </Select>
  );
};
