import { CounterBadge, tokens, Text } from "@fluentui/react-components";
import { GiftType } from "@winnerscore/common";
import { BackupImage } from "../common/BackupImage";

export function GiftCard({
  gift,
  size = 190,
}: {
  gift: GiftType;
  size?: number;
}) {
  return (
    <div
      style={{
        alignSelf: gift.rank === 1 ? "start" : "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: tokens.spacingVerticalXXL,
          alignItems: "center",
          padding:
            gift.rank === 1
              ? `${tokens.spacingVerticalNone} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL}`
              : tokens.spacingVerticalXXL,
        }}
      >
        <CounterBadge size="extra-large" count={gift.rank} />
        <BackupImage
          url={gift.image_file}
          shape="circular"
          size={size}
          alt={`Image of ${gift.title}`}
        />
        <Text size={400} weight="bold">
          {gift.title}
        </Text>
        <Text align="center">{gift.description}</Text>
      </div>
    </div>
  );
}
