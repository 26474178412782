import {
  createTableColumn,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridProps,
  DataGridRow,
  mergeClasses,
  Spinner,
  Subtitle2Stronger,
  TableColumnDefinition,
  TableColumnId,
  Text,
  tokens,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import { RankingDetailReponseType } from "@winnerscore/common";
import { useTranslation } from "react-i18next";
import { getGifts, getTipsters } from "../../api";
import { GIFTS_KEY, TIPSTERS_KEY, USERS_KEY, authFetch } from "../../utils";
import { LAYOUT_SHIFT, MOBILE_SHIFT, usePageStyle } from "../pageStyle";
import { Rank } from "../pronostics/Ranking/Rank";
import { ContactCard24Regular, PeopleTeamRegular } from "@fluentui/react-icons";
import { RankingRow } from "../pronostics/Ranking/RankingRow";
import { PeoplePicker } from "@microsoft/mgt-react";
import { useEffect, useState } from "react";
import { GiftCard } from "../gift/GiftCard";
import { useMediaQuery } from "usehooks-ts";
import { useDesktopHeader, useLanguage } from "../../store";
import { Personal } from "../pronostics/Ranking/Personal";

const WIDTHS: Record<TableColumnId, string> = {
  name: "auto",
  rank: "65px",
  points: "65px",
  perfect: "65px",
  good: "65px",
  lost: "65px",
};

const HEADER_WIDTHS: Record<TableColumnId, string> = {
  ...WIDTHS,
  points: "calc(65px + 0.5rem)",
};

type Item = RankingDetailReponseType["users"][0];

export function RankingPage() {
  const { main, aside, mainHeader, mainWithoutHeader } = usePageStyle();

  const { t } = useTranslation();
  const [filteredUsers, setFilteredUsers] = useState<Item[]>([]);
  const [userList, setUserList] = useState<Item[]>([]);
  const [search, setSearch] = useState("");
  const { lang } = useLanguage();
  const [sortState, setSortState] = useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "rank",
    sortDirection: "ascending",
  });
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const mobile = useMediaQuery(`(max-width: ${MOBILE_SHIFT})`);
  const { header } = useDesktopHeader();
  const [page] = useState(1);

  const { data: tipsters } = useQuery({
    queryKey: [TIPSTERS_KEY],
    queryFn: getTipsters,
  });

  const { data: gifts } = useQuery({
    queryKey: [GIFTS_KEY, desktop, lang],
    queryFn: async () => {
      if (desktop) {
        const data = await getGifts();
        data?.sort((giftA, giftB) => giftA.rank - giftB.rank);
        return data;
      }
    },
  });

  const { data: ranks, isLoading } = useQuery({
    queryKey: [USERS_KEY, "details", page],
    queryFn: async () => {
      const data = await authFetch<RankingDetailReponseType>(
        `${process.env.REACT_APP_BACKEND_URL}/ranking-detail?page=${page}`
      );
      return data;
    },
  });

  const [columns, setColumns] = useState<TableColumnDefinition<Item>[]>([]);

  useEffect(() => {
    const columToDisplay = [
      createTableColumn<Item>({
        columnId: "rank",
        renderHeaderCell: () => <Text>{t("rank")}</Text>,
        renderCell: (item) => {
          return <Rank rank={item.rank} change={item.change} />;
        },
        compare: (a, b) => a.rank - b.rank,
      }),
      createTableColumn<Item>({
        columnId: "name",
        renderHeaderCell: () => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ContactCard24Regular />
              <Text>{t("rankingName")}</Text>
            </div>
          );
        },
        renderCell: (item) => {
          return <RankingRow id={item.id} name={item.displayname} />;
        },
        compare: (a, b) => a.displayname.localeCompare(b.displayname),
      }),
      createTableColumn<Item>({
        columnId: "points",
        renderHeaderCell: () => (
          <Text align="center" style={{ width: "100%" }}>
            {t("matchs.points")}
          </Text>
        ),
        renderCell: (item) => (
          <Text align="center" style={{ width: "100%" }}>
            {item.total}
          </Text>
        ),
        compare: (a, b) => a.rank - b.rank,
      }),
    ];
    if (!mobile) {
      columToDisplay.push(
        createTableColumn<Item>({
          columnId: "perfect",
          renderHeaderCell: () => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {t("ranking.perfect")}
              </Text>
            );
          },
          renderCell: (item) => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {item.perfect}
              </Text>
            );
          },
        }),
        createTableColumn<Item>({
          columnId: "good",
          renderHeaderCell: () => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {t("ranking.good")}
              </Text>
            );
          },
          renderCell: (item) => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {item.good}
              </Text>
            );
          },
        }),
        createTableColumn<Item>({
          columnId: "lost",
          renderHeaderCell: () => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {t("ranking.lost")}
              </Text>
            );
          },
          renderCell: (item) => {
            return (
              <Text align="center" style={{ width: "100%" }}>
                {item.lost}
              </Text>
            );
          },
        })
      );
    }
    setColumns(columToDisplay);
  }, [mobile, t]);

  useEffect(() => {
    if (ranks?.users) {
      setUserList([...ranks.users]);
    }
  }, [ranks]);

  useEffect(() => {
    if (search) {
      setFilteredUsers(
        userList.filter((u) => {
          const name = u.displayname.toLowerCase();
          return name.includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredUsers(userList);
    }
  }, [userList, search]);

  return (
    <>
      <div
        className={mergeClasses(
          main,
          header && mainHeader,
          header === false && mainWithoutHeader
        )}
      >
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            columnGap: tokens.spacingHorizontalMNudge,
            paddingTop: tokens.spacingVerticalXL,
          }}
        >
          <Subtitle2Stronger align="start">
            {t("matchs.ranking")}
          </Subtitle2Stronger>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: tokens.colorNeutralForegroundDisabled,
              columnGap: tokens.spacingHorizontalXS,
            }}
            title={t("tipsters")}
          >
            <Text size={200}>{tipsters}</Text>
            <PeopleTeamRegular width={20} />
          </span>
        </div>
        <div
          style={{
            padding: `${tokens.spacingVerticalMNudge} ${tokens.spacingHorizontalL} ${tokens.spacingHorizontalNone} ${tokens.spacingVerticalNone}`,
          }}
        >
          <PeoplePicker
            selectionChanged={({ detail }) => {
              if (detail.length) {
                setSearch(detail[0].displayName ?? "");
              } else {
                setSearch("");
              }
            }}
            selectionMode="single"
            placeholder={t("search")}
          />
        </div>
        <div
          style={{
            overflow: "hidden",
          }}
        >
          <DataGrid
            columns={columns}
            items={filteredUsers}
            style={{ position: "relative" }}
            sortable
            sortState={sortState}
            onSortChange={(e, s) => {
              setSortState(s);
            }}
          >
            <DataGridHeader
              style={{
                position: "sticky",
                top: "0px",
                backgroundColor: tokens.colorNeutralBackground3,
                paddingRight: tokens.spacingHorizontalL,
              }}
            >
              <DataGridRow style={{ paddingTop: tokens.spacingVerticalM }}>
                {({ renderHeaderCell, columnId }) => (
                  <DataGridHeaderCell
                    style={{
                      flexShrink: 1,
                      flexGrow: columnId === "name" ? 1 : 0,
                      flexBasis: "auto",
                      width: HEADER_WIDTHS[columnId],
                    }}
                  >
                    {renderHeaderCell()}
                  </DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: tokens.spacingVerticalXXL,
                }}
              >
                <Spinner size="huge" />
              </div>
            ) : filteredUsers.length || !search ? (
              <DataGridBody<Item>
                style={{
                  overflowY: "scroll",
                  height: "calc(100% - 22px - 44px - 45px - 88px - 20px)",
                  paddingRight: tokens.spacingHorizontalL,
                }}
              >
                {({ item, rowId }) => (
                  <DataGridRow<Item> key={rowId}>
                    {({ renderCell, columnId }) => (
                      <DataGridCell
                        style={{
                          flexShrink: 1,
                          flexGrow: columnId === "name" ? 1 : 0,
                          flexBasis: "auto",
                          width: WIDTHS[columnId],
                        }}
                      >
                        {renderCell(item)}
                      </DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: tokens.spacingVerticalXXL,
                  height: "calc(100% - 22px - 44px - 45px - 88px - 20px)",
                }}
              >
                <Subtitle2Stronger align="center">
                  {t("noResults.title")}
                </Subtitle2Stronger>
                <Text align="center">{t("noResults.details")}</Text>
              </div>
            )}
          </DataGrid>
        </div>
        <Personal detail />
      </div>
      <div className={aside} style={{ display: desktop ? "" : "none" }}>
        <div
          style={{
            height: "100%",
            overflow: "hidden",
            gridRowEnd: 3,
            gridRowStart: 1,
            paddingTop: tokens.spacingVerticalXL,
          }}
        >
          <Subtitle2Stronger>{t("giftTitle")}</Subtitle2Stronger>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "calc(100% - 21px)",
            }}
          >
            {gifts?.map((gift) => (
              <GiftCard key={gift.id} gift={gift} size={100} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
