import { makeStyles, Text, tokens } from "@fluentui/react-components";
import { Clock20Regular } from "@fluentui/react-icons";
import { MOBILE_SHIFT } from "../../pageStyle";

const useStyle = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    [`@media (max-width: ${MOBILE_SHIFT})`]: {
      display: "none",
    },
  },
});

export function TimeIndicator({ time }: { time: string }) {
  const { container } = useStyle();
  const date = new Date(time);
  const formated = `${date.toLocaleDateString(
    "default"
  )} | ${date.toLocaleTimeString("default", { timeStyle: "short" })}`;
  return (
    <div className={container}>
      <span
        style={{
          width: "16px",
          height: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Clock20Regular />
      </span>
      <Text style={{ paddingLeft: tokens.spacingHorizontalXS }}>
        {formated}
      </Text>
    </div>
  );
}
