import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  makeStyles,
  Text,
  tokens,
  shorthands,
  Checkbox,
  Label,
  Spinner,
} from "@fluentui/react-components";
import Logo from "./32x32.png";
import { useTranslation } from "react-i18next";
import { RulesContent } from "./RulesContent";
import { useCallback, useRef, useState } from "react";
import { RULES_KEY, useResize } from "../../utils";
import { createUser, getRules } from "../../api";
import { useCgu, useCurrentUser } from "../../store";
import { useQuery } from "@tanstack/react-query";

const useStyle = makeStyles({
  logoContainer: {
    display: "flex",
  },
  logo: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "16px 16px",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    width: "32px",
    height: "32px",
    backgroundColor: tokens.colorBrandBackground,
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },
  title: {
    ...shorthands.padding(
      tokens.spacingVerticalMNudge,
      tokens.spacingHorizontalNone
    ),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalXS,
    maxHeight: "50vh",
  },
  actions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export function CGU() {
  const { logoContainer, logo, title, content, actions } = useStyle();
  const { t } = useTranslation();
  const cgu = useCgu();
  const [end, setEnd] = useState(false);
  const [checked, setChecked] = useState(false);
  const { id, username, company } = useCurrentUser();
  const { data, isLoading } = useQuery({
    queryKey: [RULES_KEY],
    queryFn: getRules,
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const callback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const border = entry.borderBoxSize;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _ of border.values()) {
          if (!contentRef.current) {
            return;
          }
          const valid =
            Math.abs(
              contentRef.current.scrollHeight -
                contentRef.current.scrollTop -
                contentRef.current.clientHeight
            ) <= 1;
          if (valid && !end) {
            setEnd(valid);
          }
        }
      }
    },
    [setEnd, end]
  );

  const accept = async () => {
    if (username) {
      const serverCreation = await createUser({
        id,
        displayName: username,
        company: company,
      });
      if (serverCreation?.created) {
        cgu.accept();
      }
    }
  };

  useResize(contentRef.current, callback);

  return (
    <Dialog modalType="alert" open={cgu.display}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            <div className={logoContainer}>
              <span className={logo} />
              <Text
                weight="bold"
                size={500}
                style={{ paddingLeft: tokens.spacingHorizontalL }}
              >
                Winnerscore
              </Text>
            </div>
          </DialogTitle>
          <DialogContent
            className={content}
            ref={contentRef}
            onScroll={(e) => {
              const valid =
                Math.abs(
                  e.currentTarget.scrollHeight -
                    e.currentTarget.scrollTop -
                    e.currentTarget.clientHeight
                ) <= 1;
              if (valid && !end) {
                setEnd(true);
              }
            }}
          >
            <Text as="p" size={400} weight="bold" className={title}>
              {t("rules.title")}
            </Text>
            {isLoading && <Spinner size="large" />}
            {data && <RulesContent />}
          </DialogContent>
          <DialogActions className={actions} fluid>
            <div>
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked((c) => !c);
                }}
              />
              <Label required>{t("rules.acceptLabel")}</Label>
            </div>
            <Button
              onClick={accept}
              appearance="primary"
              disabled={!end || !checked || !username}
            >
              {t("rules.accept")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
