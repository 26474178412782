import {
  Divider,
  makeStyles,
  shorthands,
  tokens,
  Text,
  mergeClasses,
  Spinner,
} from "@fluentui/react-components";
import { Balloon } from "./Balloon";
import { Add20Filled } from "@fluentui/react-icons";
import { PointExample } from "./PointExample";
import { Faq } from "./Faq";
import { useTranslation } from "react-i18next";
import { RulesContent } from "./RulesContent";
import { LAYOUT_SHIFT, usePageStyle } from "../pageStyle";
import { useMediaQuery } from "usehooks-ts";
import { RULES_KEY } from "../../utils";
import { getRules } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { ReactNode } from "react";

const useRuleStyle = makeStyles({
  divider: {
    flexGrow: 0,
    display: "none",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      display: "inherit",
    },
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      gridTemplateColumns: "1fr 1fr",
      display: "grid",
    },
  },
  faq: {
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      width: "430px",
    },
    justifyContent: "center",
    flexShrink: 0,
    display: "flex",
  },
  columns: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
    [`@media (min-width: ${LAYOUT_SHIFT})`]: {
      paddingTop: tokens.spacingVerticalXL,
    },
    paddingRight: tokens.spacingHorizontalL,
  },
  textFlex: {
    display: "flex",
    alignItems: "center",
  },
});

const useUserRulesStyle = makeStyles({
  rules: {
    fontFamily: tokens.fontFamilyBase,
    listStyleType: "disc",
    listStylePosition: "inside",
    "& > h1": {
      fontSize: tokens.fontSizeBase600,
      fontWeight: tokens.fontWeightSemibold,
      ...shorthands.padding(
        tokens.spacingVerticalM,
        tokens.spacingHorizontalNone
      ),
    },
    "& > h2": {
      fontSize: tokens.fontSizeBase500,
      fontWeight: tokens.fontWeightSemibold,
      ...shorthands.padding(
        tokens.spacingVerticalM,
        tokens.spacingHorizontalNone
      ),
    },
    "& > h3": {
      fontSize: tokens.fontSizeBase400,
      fontWeight: tokens.fontWeightSemibold,
      ...shorthands.padding(
        tokens.spacingVerticalM,
        tokens.spacingHorizontalNone
      ),
    },
    "& > h4": {
      fontSize: tokens.fontSizeBase300,
      fontWeight: tokens.fontWeightSemibold,
      ...shorthands.padding(
        tokens.spacingVerticalM,
        tokens.spacingHorizontalNone
      ),
    },
    "& > h5": {
      fontSize: tokens.fontSizeBase200,
      fontWeight: tokens.fontWeightSemibold,
      ...shorthands.padding(
        tokens.spacingVerticalM,
        tokens.spacingHorizontalNone
      ),
    },
    "& > ul": {
      listStylePosition: "inside",
    },
    "& > ul > li > p": {
      display: "inline",
    },
  },
});

export const Rules = ({
  withFaq = true,
  children,
}: {
  withFaq?: boolean;
  children?: ReactNode;
}) => {
  const { divider, faq, textFlex } = useRuleStyle();
  const { aside } = usePageStyle();
  const { rules } = useUserRulesStyle();
  const { t } = useTranslation();
  const desktop = useMediaQuery(`(min-width: ${LAYOUT_SHIFT})`);
  const { data, isLoading } = useQuery({
    queryKey: [RULES_KEY],
    queryFn: getRules,
  });

  return (
    <>
      <div
        style={{
          overflowY: "auto",
          height: `calc(100% - ${tokens.spacingVerticalXXXL})`,
          paddingTop: tokens.spacingVerticalXL,
          paddingRight: tokens.spacingHorizontalL,
        }}
      >
        <div style={{ columnCount: desktop ? 2 : 1 }}>
          <Text size={400} weight="bold" as="p">
            {t("rules.title")}
          </Text>
          {data && <RulesContent />}
          {isLoading && <Spinner size="large" />}
          <div style={{ paddingBottom: tokens.spacingVerticalL }}>
            <Text as="p">{t("rules.example.exact")}</Text>
            <p className={textFlex}>
              <Add20Filled style={{ color: "red", rotate: "45deg" }} />
              <Text style={{ paddingLeft: tokens.spacingHorizontalXS }}>
                {t("rules.example.zeroPoint")}
              </Text>
            </p>
            <p className={textFlex}>
              <Balloon color="#000000" />
              <Text
                style={{
                  paddingLeft: tokens.spacingHorizontalXS,
                }}
              >
                {t("rules.example.onePoint")}
              </Text>
            </p>
            <p className={textFlex}>
              <Balloon color="#000000" />
              <Balloon color="#000000" />
              <Balloon color="#000000" />
              <Text
                style={{
                  paddingLeft: tokens.spacingHorizontalXS,
                }}
              >
                {t("rules.example.threePoints")}
              </Text>
            </p>
          </div>
          <PointExample />
          {data && withFaq && (
            <div
              dangerouslySetInnerHTML={{ __html: data.data }}
              className={rules}
            />
          )}
        </div>
        {children}
        {/*  */}
      </div>
      {withFaq && (
        <div className={mergeClasses(aside, faq)}>
          <Divider vertical className={divider} />
          {desktop && <Faq />}
        </div>
      )}
    </>
  );
};
